import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Description = createIcon({
  displayName: 'Description',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M17.7778 0H2.22222C1 0 0 1 0 2.22222V17.7778C0 19 1 20 2.22222 20H17.7778C19 20 20 19 20 17.7778V2.22222C20 1 19 0 17.7778 0ZM11.1111 15.5556H5.55556C4.94444 15.5556 4.44444 15.0556 4.44444 14.4444C4.44444 13.8333 4.94444 13.3333 5.55556 13.3333H11.1111C11.7222 13.3333 12.2222 13.8333 12.2222 14.4444C12.2222 15.0556 11.7222 15.5556 11.1111 15.5556ZM14.4444 11.1111H5.55556C4.94444 11.1111 4.44444 10.6111 4.44444 10C4.44444 9.38889 4.94444 8.88889 5.55556 8.88889H14.4444C15.0556 8.88889 15.5556 9.38889 15.5556 10C15.5556 10.6111 15.0556 11.1111 14.4444 11.1111ZM14.4444 6.66667H5.55556C4.94444 6.66667 4.44444 6.16667 4.44444 5.55556C4.44444 4.94444 4.94444 4.44444 5.55556 4.44444H14.4444C15.0556 4.44444 15.5556 4.94444 15.5556 5.55556C15.5556 6.16667 15.0556 6.66667 14.4444 6.66667Z"
      fill="currentColor"
    />
  ),
});

export default React.memo(Description);
