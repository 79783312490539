import React, { createContext, useContext } from 'react';
import { useSession } from 'next-auth/client';
import useGetUserId from '@/hooks/useGetUserId';
import usePermissions from '@/hooks/usePermissions';

export const UserPermission = createContext(null);

export function useUserPermissions() {
  const context = useContext(UserPermission);

  if (!context) {
    throw new Error(
      'useUserPermissions must be used within an UserPermissions provider',
    );
  }

  return context;
}

export function UserPermissionsProvider(props) {
  const [session] = useSession();
  const permissions = usePermissions();
  const { loggedUserId } = useGetUserId(session);

  const context = {
    ...permissions,
    user: { ...session?.user, userId: loggedUserId },
  };

  // TODO: move new version modal to the right place
  return <UserPermission.Provider value={context} {...props} />;
}
