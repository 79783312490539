import React, { useEffect } from 'react';
import { useReactiveVar, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import getConfig from 'next/config';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { Box, useToast, useColorMode } from '@chakra-ui/react';

import { UserPermissionsProvider } from '@/contexts/userPermissions';
import { LocalCacheVersionHandlerProvider } from '@/contexts/useCacheVersionContext';
import Header from '@/components/Header/Header';
import OnlineStatusProvider from '@/components/OnlineStatusContext';
import ErrorFallback from '@/components/ErrorFallback';
import errorsVar from '@/components/GraphQLProvider/errorsVar';
import { TOAST_DEFAULT_ERROR } from '@/constants/global';
import showMenu from '@/components/GraphQLProvider/showMenu';
import ChangeVersionModalContainer from '@/containers/ChangeVersionModalContainer';
import { useSession } from 'next-auth/client';
import { USER_SETTINGS } from '@/api/users';
import Meta from '../Meta';
import styles from './Main.module.css';

const MainLayout = ({ children }) => {
  const toast = useToast();
  const { publicRuntimeConfig } = getConfig();

  const [session] = useSession() ?? [];
  const { email } = session?.user ?? {};
  const { colorMode, toggleColorMode } = useColorMode();

  const { data: settings } = useQuery(USER_SETTINGS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: { contactEmail: email },
    skip: !email,
  });

  const { lightMode } = settings?.userSettings ?? {};

  useEffect(() => {
    if (
      (lightMode && colorMode !== 'light') ||
      (lightMode === false && colorMode !== 'dark')
    ) {
      toggleColorMode();
    }
  }, [lightMode]);

  const apolloError = useReactiveVar(errorsVar);
  const showMenuValue = useReactiveVar(showMenu);

  let paddingLeft = '0';

  if (showMenuValue) {
    paddingLeft = { base: '16', lg: '48' };
  }

  useEffect(() => {
    if (apolloError) {
      toast({
        description: apolloError,
        ...TOAST_DEFAULT_ERROR,
      });

      errorsVar(null);
    }
  }, [apolloError]);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <OnlineStatusProvider>
        <LocalCacheVersionHandlerProvider>
          <UserPermissionsProvider>
            <FlagsmithProvider
              options={{
                environmentID: publicRuntimeConfig.FLAGSMITH_KEY,
              }}
              flagsmith={flagsmith}
            >
              <Meta />
              <Header isMenuOpen={showMenuValue} />
              <Box
                height="100%"
                paddingLeft={paddingLeft}
                position="relative"
                className={styles.container}
              >
                {children}
              </Box>
            </FlagsmithProvider>
          </UserPermissionsProvider>
          <ChangeVersionModalContainer />
        </LocalCacheVersionHandlerProvider>
      </OnlineStatusProvider>
    </ErrorBoundary>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default MainLayout;
