import React from 'react';
import { createIcon } from '@chakra-ui/react';

const StopButton = createIcon({
  displayName: 'StopButton',
  path: (
    <g fill="none" fillRule="evenodd">
      <rect width="36" height="36" fill="currentColor" rx="18" />
      <rect width="14" height="14" x="11" y="11" fill="#ffffff" rx="1.5" />
    </g>
  ),
  viewBox: '0 0 36 36',
});

export default React.memo(StopButton);
