import { getUserId } from '@/utils/userUtils';
import useEnabledUsers from './useEnabledUsers';

export const DEFAULT_USER_ID = 0;

const useGetUserId = session => {
  const { enabledUsers } = useEnabledUsers();

  const loggedUserId = enabledUsers?.length
    ? getUserId(session?.user.email, enabledUsers)
    : DEFAULT_USER_ID;

  return { loggedUserId };
};

export default useGetUserId;
