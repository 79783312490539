import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  Heading,
  List,
  Stack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { useHotkeys } from 'react-hotkeys-hook';
import {
  NAVIGATION_BACKGROUND_COLOR,
  NAVIGATION_MENU_ITEM_TITLE_COLOR,
} from '@/utils/colorUtils';
import { useGetItemsFilteredByPermissions } from '@/utils/permissionsUtils';
import { useRouter } from 'next/router';
import { OPEN_SHORTCUTS_MODAL } from '@/constants/hotkeys';
import UserProfile from '@/components/UserProfile';
import LogoutButton from '@/components/LogoutButton';
import FeedbackButton from '@/components/FeedbackButton';
import ShortcutsButton from '@/components/ShortcutsButton';
import { useUserPermissions } from '@/contexts/userPermissions';
import ShortcutsModal from '@/components/ShortcutsModal';
import MenuItem from '../MenuItem';
import { menuItemsList } from '../menuItemsList';
import styles from './NavigationMenu.module.css';

const NavigationMenu = ({ isMenuOpen }) => {
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigationBackground = useColorModeValue(
    NAVIGATION_BACKGROUND_COLOR.light,
    NAVIGATION_BACKGROUND_COLOR.dark,
  );
  const navigationMenuItemTitle = useColorModeValue(
    NAVIGATION_MENU_ITEM_TITLE_COLOR.light,
    NAVIGATION_MENU_ITEM_TITLE_COLOR.dark,
  );

  const { permissionToLockEntries } = useUserPermissions();

  const menuItemsFiltered = useGetItemsFilteredByPermissions(
    menuItemsList({ permissionToLockEntries }),
  );

  const showMenuClass = isMenuOpen ? styles['is-open'] : '';

  const stackClassName = `${styles['navigation-menu']} ${showMenuClass}`;

  useHotkeys(
    OPEN_SHORTCUTS_MODAL,
    () => {
      onOpen();
    },
    { preventDefault: true },
  );

  return (
    <Stack
      className={stackClassName}
      bg={navigationBackground}
      justifyContent="space-between"
      position="absolute"
      left={0}
      width={48}
      overflow="auto"
    >
      <Stack>
        <UserProfile />
        <Stack p={2} spacing={3}>
          {menuItemsFiltered.map(({ title, options }) => (
            <Stack key={title}>
              <Heading
                px={2}
                mb={2}
                as="h2"
                size="md"
                color={navigationMenuItemTitle}
                cursor="default"
              >
                {title}
              </Heading>
              <List>
                {options.map(({ icon, label, url }) => {
                  const basePath = router.pathname.split('/', 3).join('/');
                  const isCurrentPage = url.includes(basePath);

                  return (
                    <MenuItem
                      key={label}
                      icon={icon}
                      label={label}
                      url={url}
                      isCurrentPage={isCurrentPage}
                    />
                  );
                })}
              </List>
            </Stack>
          ))}
          <LogoutButton />
        </Stack>
      </Stack>
      <Flex direction="column" px="2" pb="8">
        <Flex pb="4">
          <ShortcutsButton onClick={onOpen} />
          <ShortcutsModal isOpen={isOpen} onClose={onClose} />
        </Flex>
        <Flex>
          <FeedbackButton />
        </Flex>
      </Flex>
    </Stack>
  );
};

NavigationMenu.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

export default NavigationMenu;
