import React from 'react';
import { createIcon } from '@chakra-ui/react';

const TimeEntriesClose = createIcon({
  displayName: 'TimeEntriesClose',
  viewBox: '0 0 12 8',
  path: (
    <path
      d="M10.59 0.0899963L6 4.67L1.41 0.0899963L0 1.5L6 7.5L12 1.5L10.59 0.0899963Z"
      fill="currentColor"
    />
  ),
});

export default React.memo(TimeEntriesClose);
