import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Command = createIcon({
  displayName: 'command',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M14.5 0.916992C12.57 0.916992 11 2.48699 11 4.41699V5.91699H7V4.41699C7 2.48699 5.43 0.916992 3.5 0.916992C1.57 0.916992 0 2.48699 0 4.41699C0 6.34699 1.57 7.91699 3.5 7.91699H5V11.917H3.5C1.57 11.917 0 13.487 0 15.417C0 17.347 1.57 18.917 3.5 18.917C5.43 18.917 7 17.347 7 15.417V13.917H11V15.417C11 17.347 12.57 18.917 14.5 18.917C16.43 18.917 18 17.347 18 15.417C18 13.487 16.43 11.917 14.5 11.917H13V7.91699H14.5C16.43 7.91699 18 6.34699 18 4.41699C18 2.48699 16.43 0.916992 14.5 0.916992ZM13 5.91699V4.41699C13 3.58699 13.67 2.91699 14.5 2.91699C15.33 2.91699 16 3.58699 16 4.41699C16 5.24699 15.33 5.91699 14.5 5.91699H13ZM3.5 5.91699C2.67 5.91699 2 5.24699 2 4.41699C2 3.58699 2.67 2.91699 3.5 2.91699C4.33 2.91699 5 3.58699 5 4.41699V5.91699H3.5ZM7 11.917V7.91699H11V11.917H7ZM14.5 16.917C13.67 16.917 13 16.247 13 15.417V13.917H14.5C15.33 13.917 16 14.587 16 15.417C16 16.247 15.33 16.917 14.5 16.917ZM3.5 16.917C2.67 16.917 2 16.247 2 15.417C2 14.587 2.67 13.917 3.5 13.917H5V15.417C5 16.247 4.33 16.917 3.5 16.917Z"
      fill="currentColor"
    />
  ),
});

export default React.memo(Command);
