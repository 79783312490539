import React from 'react';
import PropTypes from 'prop-types';
import { Code, Text, useColorMode } from '@chakra-ui/react';

export const Leaf = ({ attributes, children, leaf }) => {
  const { colorMode } = useColorMode();

  if (leaf.bold) {
    // eslint-disable-next-line
    children = <Text as="b">{children}</Text>;
  }

  if (leaf.code) {
    // eslint-disable-next-line
    children = (
      <Code
        padding="3px"
        backgroundColor={colorMode === 'dark' ? 'gray.700' : 'gray.200'}
        fontSize="90%"
      >
        {children}
      </Code>
    );
  }

  if (leaf.italic) {
    // eslint-disable-next-line
    children = <Text as="i">{children}</Text>;
  }

  if (leaf.underline) {
    // eslint-disable-next-line
    children = <Text as="u">{children}</Text>;
  }

  return <span {...attributes}>{children}</span>;
};

Leaf.propTypes = {
  attributes: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.element.isRequired,
  leaf: PropTypes.objectOf(PropTypes.any).isRequired,
};
