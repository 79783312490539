import { makeVar } from '@apollo/client';
import { formatTime, formatStandardDate } from '@/utils/dateUtils';
import { DEFAULT_PROJECT_ID, DEFAULT_TAG_ID } from '@/constants/global';

export const initialTimeEntryState = {
  id: '',
  description: '',
  userId: null,
  projectId: DEFAULT_PROJECT_ID,
  tagId: DEFAULT_TAG_ID,
  startedAt: formatTime(new Date()),
  finishedAt: formatTime(new Date()),
  date: formatStandardDate(new Date()),
  isToBill: false,
  isProjectRequiredNotSelected: undefined,
  isTagRequiredNotSelected: undefined,
};

const timeEntryCreationVar = makeVar(initialTimeEntryState);

export const selectedUserGroupVar = makeVar();

export const clearTimeEntryState = () => {
  timeEntryCreationVar({
    ...timeEntryCreationVar(),
    id: '',
    description: '',
    projectId: DEFAULT_PROJECT_ID,
    tagId: DEFAULT_TAG_ID,
    isToBill: false,
    isProjectRequiredNotSelected: undefined,
    isTagRequiredNotSelected: undefined,
  });
};

export const updateTimeEntryState = newStateObj => {
  return timeEntryCreationVar({
    ...timeEntryCreationVar(),
    ...newStateObj,
  });
};

export default timeEntryCreationVar;
