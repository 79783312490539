const colors = {
  gray: {
    50: '#F7FAFC',
    100: '#EDF2F7',
    200: '#E2E8F0',
    300: '#CBD5E0',
    400: '#A0AEC0',
    500: '#718096',
    600: '#4A5568',
    700: '#2D3748',
    800: '#212121',
    900: '#181818',
  },
  alpha: {
    50: '#F7F7FC',
    100: '#E9E9FF',
    200: '#CBCBEB',
    300: '#ADADE0',
    400: '#9191DF',
    500: '#4A4AB2',
    600: '#5151BC',
    700: '#3E3EA1',
    800: '#272764',
    900: '#25254C',
  },
  beta: {
    50: '#FEF6FA',
    100: '#FCE4EF',
    200: '#FAD2E5',
    300: '#F5ADD0',
    400: '#F189BB',
    500: '#ED64A6',
    600: '#E83B8E',
    700: '#C7176C',
    800: '#B31561',
    900: '#89104A',
  },
  gamma: {
    50: '#F0FFF4',
    100: '#C6F6D5',
    200: '#9AE6B4',
    300: '#68D391',
    400: '#48BB78',
    500: '#38A169',
    600: '#2F855A',
    700: '#276749',
    800: '#22543D',
    900: '#1C4532',
  },
  delta: {
    50: '#EFFDFF',
    100: '#D6FAFF',
    200: '#AAF2FC',
    300: '#8CE1ED',
    400: '#14B6CD',
    500: '#1095A8',
    600: '#0C707E',
    700: '#084B54',
    800: '#04252A',
    900: '#021315',
  },
  epsilon: {
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    500: '#E53E3E',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
  },
  zeta: {
    50: '#FFFFF0',
    100: '#FEFCBF',
    200: '#FAF089',
    300: '#F6E05E',
    400: '#ECC94B',
    500: '#D69E2E',
    600: '#B7791F',
    700: '#975A16',
    800: '#744210',
    900: '#5F370E',
  },
};

export default colors;
