import React from 'react';
import { createIcon } from '@chakra-ui/react';

const MandatoryAsterisk = createIcon({
  displayName: 'MandatoryAsterisk',
  viewBox: '0 0 6.5 7',
  path: (
    <path
      d="M0.866699 5.19613L2.29248 3.41878L0.222168 2.83285L0.612793 1.68701L2.64404 2.4943L2.52035 0.222168H3.78337L3.64665 2.53988L5.63883 1.7391L6.02295 2.90446L3.92008 3.4904L5.30029 5.22868L4.27816 5.94482L3.08024 4.04378L1.88883 5.88623L0.866699 5.19613Z"
      fill="white"
    />
  ),
});

export default MandatoryAsterisk;
