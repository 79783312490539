import React from 'react';
import { createIcon } from '@chakra-ui/react';

const DollarSignCircle = createIcon({
  displayName: 'DollarSignCircle',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11.41 16.09V16.67C11.41 17.4 10.81 18 10.08 18H10.07C9.34 18 8.74 17.4 8.74 16.67V16.07C7.41 15.79 6.23 15.06 5.73 13.83C5.5 13.28 5.93 12.67 6.53 12.67H6.77C7.14 12.67 7.44 12.92 7.58 13.27C7.87 14.02 8.63 14.54 10.09 14.54C12.05 14.54 12.49 13.56 12.49 12.95C12.49 12.12 12.05 11.34 9.82 10.81C7.34 10.21 5.64 9.19 5.64 7.14C5.64 5.42 7.03 4.3 8.75 3.93V3.33C8.75 2.6 9.35 2 10.08 2H10.09C10.82 2 11.42 2.6 11.42 3.33V3.95C12.8 4.29 13.67 5.15 14.05 6.21C14.25 6.76 13.83 7.34 13.24 7.34H12.98C12.61 7.34 12.31 7.08 12.21 6.72C11.98 5.96 11.35 5.47 10.09 5.47C8.59 5.47 7.69 6.15 7.69 7.11C7.69 7.95 8.34 8.5 10.36 9.02C12.38 9.54 14.54 10.41 14.54 12.93C14.52 14.76 13.15 15.76 11.41 16.09Z"
      fill="currentColor"
    />
  ),
});

export default React.memo(DollarSignCircle);
