import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import NextLink from 'next/link';
import { OnlineStatusContext } from '@/components/OnlineStatusContext';
import {
  Box,
  Link,
  ListIcon,
  ListItem,
  useColorModeValue,
  Flex,
  Text,
} from '@chakra-ui/react';
import {
  NAVIGATION_MENU_ITEM_COLOR,
  NAVIGATION_MENU_ITEM_BACKGROUND_HOVER_COLOR,
  CURRENT_NAVIGATION_MENU_ITEM_COLOR,
  CURRENT_NAVIGATION_MENU_ITEM_BACKGROUND_COLOR,
} from '@/utils/colorUtils';

const TIMER_LABEL = 'Timer';
const REPORTS_LABEL = 'Reports';

const MenuItem = ({ icon, label, url, isCurrentPage }) => {
  const isOnline = useContext(OnlineStatusContext);
  const currentNavigationMenuItemColor = useColorModeValue(
    CURRENT_NAVIGATION_MENU_ITEM_COLOR.light,
    CURRENT_NAVIGATION_MENU_ITEM_COLOR.dark,
  );

  const currentNavigationMenuItemBackgroundColor = useColorModeValue(
    CURRENT_NAVIGATION_MENU_ITEM_BACKGROUND_COLOR.light,
    CURRENT_NAVIGATION_MENU_ITEM_BACKGROUND_COLOR.dark,
  );

  const navigationMenuItemBackgroundHoverColor = useColorModeValue(
    NAVIGATION_MENU_ITEM_BACKGROUND_HOVER_COLOR.light,
    NAVIGATION_MENU_ITEM_BACKGROUND_HOVER_COLOR.dark,
  );

  const navigationMenuItemColor = useColorModeValue(
    NAVIGATION_MENU_ITEM_COLOR.light,
    NAVIGATION_MENU_ITEM_COLOR.dark,
  );

  const navigationMenuItemHoverStyles = {
    bg: navigationMenuItemBackgroundHoverColor,
  };

  const isEnabled = useMemo(() => {
    if (isOnline) {
      return true;
    }

    return label === TIMER_LABEL;
  }, [isOnline, label]);

  const dataTestId = () => {
    if (label === TIMER_LABEL) {
      return 'timerLink';
    }
    if (label === REPORTS_LABEL) {
      return 'reportsTitle';
    }

    return '';
  };

  const getComponent = useMemo(() => {
    if (isEnabled) {
      return (
        <NextLink href={url}>
          <Link
            href={url}
            display="block"
            px={3}
            py={2}
            rounded="lg"
            bg={isCurrentPage && currentNavigationMenuItemBackgroundColor}
            _hover={navigationMenuItemHoverStyles}
            data-testid={dataTestId()}
          >
            <Flex align="center">
              <ListIcon
                as={icon}
                ml="px"
                w={5}
                h={5}
                data-testid="menu_item_icon"
              />
              <Text>{label}</Text>
            </Flex>
          </Link>
        </NextLink>
      );
    }

    return (
      <Box
        cursor={isEnabled ? 'pointer' : 'not-allowed'}
        px={3}
        py={2}
        rounded="lg"
        bg={isCurrentPage && currentNavigationMenuItemBackgroundColor}
        _hover={navigationMenuItemHoverStyles}
      >
        <Flex align="center">
          <ListIcon as={icon} w={5} h={5} data-testid="menu_item_icon" />
          <Text>{label}</Text>
        </Flex>
      </Box>
    );
  }, [
    isEnabled,
    currentNavigationMenuItemBackgroundColor,
    navigationMenuItemHoverStyles,
  ]);

  return (
    <ListItem
      key={label}
      mb="2"
      color={
        isCurrentPage ? currentNavigationMenuItemColor : navigationMenuItemColor
      }
    >
      {getComponent}
    </ListItem>
  );
};

MenuItem.propTypes = {
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string,
  url: PropTypes.string.isRequired,
  isCurrentPage: PropTypes.bool.isRequired,
};

MenuItem.defaultProps = {
  label: '',
};

export default MenuItem;
