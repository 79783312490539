import React from 'react';
import { Flex, Text, Button, Image } from '@chakra-ui/react';
import { GENERIC_ERROR_MESSAGE } from '@/constants/global';
import { clearStorageCustom } from '@/utils/clearStorageUtils';

const ErrorFallback = () => {
  const handleRefreshClick = () => {
    clearStorageCustom();
    window.location.reload();
  };

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      height="100vh"
      flexDir="column"
    >
      <Image src="/kubrickLogo.png" mb="16" />
      <Text color="black" fontSize="xl">
        {GENERIC_ERROR_MESSAGE}
      </Text>
      <Button
        color="white"
        bg="gray.900"
        fontSize="sm"
        onClick={handleRefreshClick}
        m="4"
      >
        Refresh
      </Button>
    </Flex>
  );
};

export default ErrorFallback;
