import React from 'react';
import { createIcon } from '@chakra-ui/react';

const TickButton = createIcon({
  displayName: 'TickButton',
  path: (
    <g fill="none" fillRule="evenodd">
      <rect width="40" height="40" fill="white" rx="20" />
      <g fill="currentColor">
        <path d="M20 0C9 0 0 9 0 20 0 31 9 40 20 40 31 40 40 31 40 20 40 9 31 0 20 0ZM17 23.4L13.1 19.4C12.5 18.9 11.5 18.9 10.9 19.4 10.4 20 10.4 21 10.9 21.6L15.9 26.6C16.5 27.1 17.5 27.1 18.1 26.6L29.1 15.6C29.6 15 29.6 14 29.1 13.4 28.5 12.9 27.5 12.9 26.9 13.4L17 23.4Z" />
      </g>
    </g>
  ),
  viewBox: '0 0 40 40',
});

export default React.memo(TickButton);
