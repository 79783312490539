import React from 'react';
import { IconButton, useColorModeValue } from '@chakra-ui/react';
import { HEADER_ICONS_COLOR } from '@/utils/colorUtils';
import showMenu from '@/components/GraphQLProvider/showMenu';
import { useReactiveVar } from '@apollo/client';
import { ChevronOpenIcon, ChevronCloseIcon } from '@/components/icons';

const SideMenuControl = () => {
  const iconButtonColor = useColorModeValue(
    HEADER_ICONS_COLOR.light,
    HEADER_ICONS_COLOR.dark,
  );

  const isMenuOpen = useReactiveVar(showMenu);

  const handleClickMenu = () => showMenu(!isMenuOpen);

  const menuIcon = isMenuOpen ? <ChevronCloseIcon /> : <ChevronOpenIcon />;

  return (
    <IconButton
      variant="ghost"
      colorScheme="delta"
      color={iconButtonColor}
      aria-label={isMenuOpen ? 'chevronOpen' : 'chevronClose'}
      onClick={handleClickMenu}
      fontSize="4xl"
      icon={menuIcon}
    />
  );
};

export default SideMenuControl;
