import React from 'react';
import {
  Flex,
  Text,
  useColorModeValue,
  Show,
  Hide,
  Link,
} from '@chakra-ui/react';
import { FeedbackIcon } from '@/components/icons';
import {
  NAVIGATION_MENU_ITEM_COLOR,
  NAVIGATION_MENU_ITEM_BACKGROUND_HOVER_COLOR,
} from '@/utils/colorUtils';
import Tooltip from '@/components/Tooltip';

const FeedbackButton = () => {
  const navigationMenuItemBackgroundHoverColor = useColorModeValue(
    NAVIGATION_MENU_ITEM_BACKGROUND_HOVER_COLOR.light,
    NAVIGATION_MENU_ITEM_BACKGROUND_HOVER_COLOR.dark,
  );

  const navigationMenuItemColor = useColorModeValue(
    NAVIGATION_MENU_ITEM_COLOR.light,
    NAVIGATION_MENU_ITEM_COLOR.dark,
  );

  const buttonHoverStyles = {
    bg: navigationMenuItemBackgroundHoverColor,
  };

  const marginRight = { base: 0, lg: 3 };

  return (
    <Link
      href="https://forms.gle/Uxp2ww7frDvkSr6K8"
      isExternal
      style={{ textDecoration: 'none' }}
      rounded="lg"
      width="full"
    >
      <Flex
        alignItems="center"
        bg="transparent"
        px="3"
        py="2"
        fontWeight="normal"
        rounded="lg"
        color={navigationMenuItemColor}
        _hover={buttonHoverStyles}
        data-tip
        data-for="feedback"
      >
        <FeedbackIcon w="5" h="5" mr={marginRight} />
        <Show above="lg">
          <Text>Feedback</Text>
        </Show>
        <Hide above="lg">
          <Tooltip id="feedback" content="Feedback" />
        </Hide>
      </Flex>
    </Link>
  );
};

export default FeedbackButton;
