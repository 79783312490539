import React from 'react';
import { createIcon } from '@chakra-ui/react';

const ChevronOpen = createIcon({
  displayName: 'ChevronOpen',
  viewBox: '-7 -7 36 36',
  path: (
    <g fill="currentColor">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 7.29289C16.1095 7.68342 16.1095 8.31658 16.5 8.70711L19.7929 12L16.5 15.2929C16.1095 15.6834 16.1095 16.3166 16.5 16.7071C16.8905 17.0976 17.5237 17.0976 17.9142 16.7071L21.5607 13.0607C22.1464 12.4749 22.1464 11.5251 21.5607 10.9393L17.9142 7.29289C17.5237 6.90237 16.8905 6.90237 16.5 7.29289Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.207 7C14.207 6.44772 13.7593 6 13.207 6H3.20703C2.65475 6 2.20703 6.44772 2.20703 7C2.20703 7.55228 2.65475 8 3.20703 8H13.207C13.7593 8 14.207 7.55228 14.207 7ZM14.207 12C14.207 11.4477 13.7593 11 13.207 11H3.20703C2.65475 11 2.20703 11.4477 2.20703 12C2.20703 12.5523 2.65475 13 3.20703 13H13.207C13.7593 13 14.207 12.5523 14.207 12ZM14.207 17C14.207 16.4477 13.7593 16 13.207 16H3.20703C2.65475 16 2.20703 16.4477 2.20703 17C2.20703 17.5523 2.65475 18 3.20703 18H13.207C13.7593 18 14.207 17.5523 14.207 17Z"
      />
    </g>
  ),
});

export default ChevronOpen;
