import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSession } from 'next-auth/client';
import {
  Avatar,
  Text,
  useColorModeValue,
  Show,
  Hide,
  Divider,
  Flex,
  IconButton,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import {
  BORDER_COLOR,
  NAVIGATION_DIVIDER_BACKGROUND_COLOR,
} from '@/utils/colorUtils';
import { useQuery, useMutation } from '@apollo/client';
import { GET_USER, UPDATE_USER_STUDIO } from '@/api/users';
import { UpdateArrowsCircleIcon } from '@/components/icons';
import { TOAST_DEFAULT_SUCCESS, TOAST_DEFAULT_ERROR } from '@/constants/global';

const UserProfile = ({
  flexDirection,
  avatarSize,
  capitalizedNameSize,
  emailSize,
  showUpdateStudioButton,
}) => {
  const [session] = useSession();
  const toast = useToast();

  const borderColor = useColorModeValue(BORDER_COLOR.light, BORDER_COLOR.dark);
  const nameColor = useColorModeValue('alpha.900', 'gray.100');
  const emailColor = useColorModeValue('gray.600', 'gray.300');

  const { data } = useQuery(GET_USER, {
    variables: { email: session.user.email },
  });

  const studio = useMemo(() => data?.user?.studio?.name, [data]);

  const [updateUserStudio, { data: mutationData }] = useMutation(
    UPDATE_USER_STUDIO,
    {
      refetchQueries: [GET_USER, 'user'],
      onCompleted: () => {
        if (mutationData) {
          toast({
            title: 'Update studio',
            description: 'User studio updated successfully',
            ...TOAST_DEFAULT_SUCCESS,
          });
        }
      },
      onError: () => {
        toast(TOAST_DEFAULT_ERROR);
      },
    },
  );

  const onUpdateUserStudio = useCallback(async () => {
    await updateUserStudio({
      variables: { userId: parseFloat(data?.user?.id) },
    });
  }, [data]);

  if (session && session.user) {
    const { name, image, email } = session.user;

    const capitalizedName = name
      .split('.')
      .map(i => i[0].toUpperCase() + i.slice(1).toLowerCase())
      .join(' ');

    const navigationDividerBackground = useColorModeValue(
      NAVIGATION_DIVIDER_BACKGROUND_COLOR.light,
      NAVIGATION_DIVIDER_BACKGROUND_COLOR.dark,
    );

    return (
      <Flex
        direction={flexDirection}
        mt={{ base: 5, lg: 0 }}
        p={flexDirection === 'column' ? { base: 2, lg: 4 } : ''}
        spacing={{ base: 4, lg: 3 }}
        borderColor={borderColor}
        alignItems="center"
        justify="center"
      >
        <Avatar
          alignSelf={{ base: 'center', lg: 'start' }}
          size={avatarSize}
          name={name}
          src={image}
          mr={{ base: '0', lg: '6' }}
        />
        <Show above="lg">
          <Flex direction="column" mt="2" w="100%">
            <Text
              fontSize={capitalizedNameSize}
              maxW="100%"
              isTruncated
              cursor="default"
              fontWeight="bold"
              color={nameColor}
            >
              {capitalizedName}
            </Text>
            <Flex direction="row" alignItems="center">
              <Text
                fontSize={emailSize}
                maxW="100%"
                isTruncated
                cursor="default"
                color={emailColor}
              >
                {studio || email}
              </Text>
              {showUpdateStudioButton && (
                <Tooltip label="Update studio">
                  <IconButton
                    ml="0.5"
                    aria-label="Update studio"
                    onClick={onUpdateUserStudio}
                    fontSize="xl"
                    variant="ghost"
                    size="xs"
                    icon={<UpdateArrowsCircleIcon color="#4A4AB2" />}
                    _focus={{
                      boxShadow: 'transparent',
                    }}
                  />
                </Tooltip>
              )}
            </Flex>
          </Flex>
        </Show>
        <Hide above="lg">
          <Divider pt={3} borderColor={navigationDividerBackground} />
        </Hide>
      </Flex>
    );
  }

  return null;
};

UserProfile.propTypes = {
  flexDirection: PropTypes.string,
  avatarSize: PropTypes.string,
  capitalizedNameSize: PropTypes.string,
  emailSize: PropTypes.string,
  showUpdateStudioButton: PropTypes.bool,
};

UserProfile.defaultProps = {
  flexDirection: 'column',
  avatarSize: 'sm',
  capitalizedNameSize: 'sm',
  emailSize: 'xs',
  showUpdateStudioButton: false,
};

export default React.memo(UserProfile);
