// log the pageview with their URL
import React from 'react';
import getConfig from 'next/config';
import Script from 'next/script';

export const pageview = url => {
  if (window.gtag !== undefined) {
    window.gtag('config', process.env.GOOGLE_ANALYTICS_MEASURMENT_ID, {
      page_path: url,
    });
  }
};

// log specific events happening.
export const event = ({ action, params }) => {
  if (window.gtag !== undefined) {
    window.gtag('event', action, params);
  }
};

export const GaInitializer = () => {
  const { publicRuntimeConfig } = getConfig();

  if (publicRuntimeConfig.GOOGLE_ANALYTICS_MEASUREMENT_ID) {
    return (
      <>
        {/* Global site tag (gtag.js) - Google Analytics  */}
        <Script
          src={`https://www.googletagmanager.com/gtag/js?id=${publicRuntimeConfig.GOOGLE_ANALYTICS_MEASUREMENT_ID}`}
          strategy="afterInteractive"
        />
        <Script id="google-analytics" strategy="afterInteractive">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', '${publicRuntimeConfig.GOOGLE_ANALYTICS_MEASUREMENT_ID}');
        `}
        </Script>
      </>
    );
  }

  return null;
};
