import {
  UNAUTHORIZED,
  NOT_FOUND_ERROR_PAGE,
  INTERNAL_SERVER_ERROR_PAGE,
} from '@/constants/paths';
import { YES_PARAM, NO_PARAM } from '@/constants/params';

const errorPaths = [
  UNAUTHORIZED,
  NOT_FOUND_ERROR_PAGE,
  INTERNAL_SERVER_ERROR_PAGE,
];

/**
 * @param {String} pathname the pathname returned by next router
 * It returns true if the pathname starts
 *  with the not found error page ( /404 )
 *  or the internal server error page ( /500 )
 */
export const isErrorPage = pathname => errorPaths.includes(pathname);

/**
 * It returns a new URL object with the query parameter updated based on the 'valueToCompare'
 * @param valueToCompare - The value to compare against the condition.
 * @param pathname - The pathname of the URL.
 * @param query - the query object from the url
 * @param paramName - The name of the param you want to add to the URL.
 * @param deleteParamNameCondition - If 'valueToCompare' is equal to this, then the 'paramName' will be deleted from the query.
 * @param yesParamCondition - The value that will be used to set the param to YES_PARAM
 * @returns A function that returns an URL object.
 */
export const getUrl = (
  valueToCompare,
  pathname,
  query,
  paramName,
  deleteParamNameCondition,
  yesParamCondition,
) => {
  const url = {
    pathname,
    query: { ...query },
  };

  if (valueToCompare === deleteParamNameCondition && query) {
    // eslint-disable-next-line no-param-reassign
    delete query[paramName];
    url.query = { ...query };
  } else if (valueToCompare === yesParamCondition) {
    url.query[paramName] = YES_PARAM;
  } else {
    url.query[paramName] = NO_PARAM;
  }

  return url;
};
