import React from 'react';
import PropTypes from 'prop-types';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';

const ChakraThemeProvider = ({ children }) => (
  <ChakraProvider theme={theme}>{children}</ChakraProvider>
);

ChakraThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ChakraThemeProvider;
