import React, { useState, useEffect } from 'react';
import { queueLink } from '@/components/GraphQLProvider/apolloClient';
import { useToast } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export const OnlineStatusContext = React.createContext(true);

const OFFLINE_MESSAGE = 'You are offline';
const ONLINE_MESSAGE = 'You are back online';
const ONLINE_STATUS_TOAST_ID = 'online-status-toast';
const OFFLINE_STATUS_TOAST_ID = 'offline-status-toast';
const STATUS_DURATION = 2000;

const OnlineStatusProvider = ({ children }) => {
  const [onlineStatus, setOnlineStatus] = useState(true);
  const toast = useToast();

  useEffect(() => {
    window.addEventListener('offline', () => {
      setOnlineStatus(false);

      toast.close(ONLINE_STATUS_TOAST_ID);

      if (!toast.isActive(OFFLINE_STATUS_TOAST_ID)) {
        toast({
          id: OFFLINE_STATUS_TOAST_ID,
          title: OFFLINE_MESSAGE,
          status: 'error',
          isClosable: true,
          position: 'top',
          duration: STATUS_DURATION,
        });
      }
    });
    window.addEventListener('online', () => {
      setOnlineStatus(true);

      toast.close(OFFLINE_STATUS_TOAST_ID);

      if (!toast.isActive(ONLINE_STATUS_TOAST_ID)) {
        toast({
          id: ONLINE_STATUS_TOAST_ID,
          title: ONLINE_MESSAGE,
          status: 'success',
          isClosable: true,
          position: 'top',
          duration: STATUS_DURATION,
        });
      }
    });

    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(false);
      });
      window.removeEventListener('online', () => {
        setOnlineStatus(true);
      });
    };
  }, []);

  useEffect(() => {
    if (onlineStatus) {
      queueLink.open();
    } else {
      queueLink.close();
    }
  }, [onlineStatus]);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
};

OnlineStatusProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OnlineStatusProvider;
