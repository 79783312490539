import React, {
  useEffect,
  useState,
  createContext,
  useContext,
  useRef,
} from 'react';
import { useToast } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { useQuery } from '@apollo/client';

import { CACHE_VERSION, CACHE_VERSION_SUBSCRIPTION } from '@/api/appSettings';
import { OnlineStatusContext } from '@/components/OnlineStatusContext';
import { TOAST_DEFAULT_SUCCESS } from '@/constants/global';
import { clearStorageCustom } from '@/utils/clearStorageUtils';

export const LocalCacheHandler = createContext(null);

export function useVersionCacheHandler() {
  const context = useContext(LocalCacheHandler);

  if (!context) {
    throw new Error(
      'useLocalCacheHandler must be used within an LocalCacheHandler provider',
    );
  }

  return context;
}

export function LocalCacheVersionHandlerProvider(props) {
  const isOnline = useContext(OnlineStatusContext);
  const { children } = props;
  const toast = useToast();

  const [isCacheOutdated, setIsCacheOutdated] = useState(false);
  const refCacheVersion = useRef(null);

  const cacheVersion = useQuery(CACHE_VERSION, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });
  const { refetch, client, subscribeToMore, loading } = cacheVersion;

  useEffect(() => {
    if (
      refCacheVersion.current === null ||
      refCacheVersion.current === undefined
    ) {
      refetch();
    }
  }, [refetch]);

  useEffect(() => {
    refCacheVersion.current = cacheVersion.data?.cacheVersion.cacheVersion;
    if (
      refCacheVersion.current === null ||
      refCacheVersion.current === undefined
    ) {
      refetch();
    }
  }, [loading]);

  useEffect(() => {
    subscribeToMore({
      document: CACHE_VERSION_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) {
          refCacheVersion.current = prev.cacheVersion?.cacheVersion;

          return prev.cacheVersion?.cacheVersion;
        }
        const newCacheVersion =
          subscriptionData.data.cacheVersionUpdated.cacheVersion;

        if (
          Number.isInteger(refCacheVersion.current) &&
          refCacheVersion.current !== newCacheVersion
        ) {
          setIsCacheOutdated(true);
        }

        return { cacheVersion: subscriptionData.data.cacheVersionUpdated };
      },
    });
  }, []);

  useEffect(() => {
    if (isCacheOutdated) {
      toast({
        ...TOAST_DEFAULT_SUCCESS,
        title: 'Force Sync: The app will try to fetch the latest data',
      });
      client.cache.reset();
      clearStorageCustom();
      window.location.reload();
    }
  }, [isCacheOutdated]);

  useEffect(() => {
    if (isOnline && refCacheVersion.current !== undefined) {
      refetch();
    }
  }, [isOnline]);

  const context = {
    cacheVersion,
    isCacheOutdated,
    setIsCacheOutdated,
    refCacheVersion,
    loading,
  };

  return (
    <LocalCacheHandler.Provider value={context} {...props}>
      <>{children}</>
    </LocalCacheHandler.Provider>
  );
}

LocalCacheVersionHandlerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
