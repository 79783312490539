import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Checklist = createIcon({
  displayName: 'Checklist',
  viewBox: '0 0 18 18',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.27616 4.8882C2.88509 5.28263 2.25182 5.28263 1.86075 4.8882L0.292377 3.30635C-0.0974591 2.91316 -0.0974591 2.27833 0.292377 1.88514C0.683447 1.49071 1.31672 1.49071 1.70779 1.88514L2.56476 2.74947L4.99749 0.295824C5.38856 -0.0986079 6.02184 -0.0986079 6.41291 0.295824C6.80272 0.688982 6.80275 1.32375 6.413 1.71694L3.27616 4.8882ZM3.27616 11.1632C2.88509 11.5577 2.25182 11.5577 1.86075 11.1632L0.292377 9.58138C-0.0974591 9.1882 -0.0974591 8.55336 0.292377 8.16017C0.683447 7.76574 1.31672 7.76574 1.70779 8.16017L2.56476 9.02451L4.99749 6.57086C5.38856 6.17643 6.02184 6.17643 6.41291 6.57086C6.8027 6.964 6.80275 7.59873 6.41305 7.99193L3.27616 11.1632ZM3.27617 17.704C2.8851 18.0984 2.25183 18.0984 1.86076 17.704L0.292384 16.1221C-0.0974524 15.729 -0.0974524 15.0941 0.292384 14.7009C0.683454 14.3065 1.31673 14.3065 1.7078 14.7009L2.56476 15.5653L4.9975 13.1116C5.38857 12.7172 6.02185 12.7172 6.41292 13.1116C6.80268 13.5047 6.80275 14.1394 6.41313 14.5326M6.41313 14.5326L3.27617 17.704L6.41313 14.5326ZM16.9981 2.65725C17.5518 2.65725 18 3.1125 18 3.66554C18 4.21858 17.5518 4.67384 16.9981 4.67384H9.42401C8.87023 4.67384 8.42207 4.21858 8.42207 3.66554C8.42207 3.1125 8.87023 2.65725 9.42401 2.65725H16.9981ZM16.9981 8.93229C17.5518 8.93229 18 9.38754 18 9.94058C18 10.4936 17.5518 10.9489 16.9981 10.9489H9.42401C8.87023 10.9489 8.42207 10.4936 8.42207 9.94058C8.42207 9.38754 8.87023 8.93229 9.42401 8.93229H16.9981ZM16.9981 15.4731C17.5518 15.4731 18 15.9283 18 16.4813C18 17.0344 17.5518 17.4896 16.9981 17.4896H9.42402C8.87024 17.4896 8.42208 17.0344 8.42208 16.4813C8.42208 15.9283 8.87024 15.4731 9.42402 15.4731H16.9981Z"
      fill="currentColor"
    />
  ),
});

export default React.memo(Checklist);
