export const clearStorageCustom = () => {
  //  To prevent clear item
  const selectedViewBreakdownOption = JSON.parse(
    localStorage.getItem('selectedProjectBreakdownOption'),
  );
  const selectedViewTypeOption = JSON.parse(
    localStorage.getItem('selectedViewTypeOption'),
  );

  localStorage.clear();
  sessionStorage.clear();

  localStorage.setItem(
    'selectedProjectBreakdownOption',
    JSON.stringify(selectedViewBreakdownOption),
  );
  localStorage.setItem(
    'selectedViewTypeOption',
    JSON.stringify(selectedViewTypeOption),
  );
};
