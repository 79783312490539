import React from 'react';
import { createIcon } from '@chakra-ui/react';

const FolderOutline = createIcon({
  displayName: 'FolderOutline',
  viewBox: '0 0 20 16',
  width: '20',
  height: '16',
  fill: 'none',
  path: (
    <path
      d="M7.17 2L9.17 4H18V14H2V2H7.17ZM8 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2H10L8 0Z"
      fill="currentColor"
    />
  ),
});

export default React.memo(FolderOutline);
