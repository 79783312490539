import React from 'react';
import { createIcon } from '@chakra-ui/react';

const ManualMode = createIcon({
  displayName: 'ManualMode',
  viewBox: '0 0 20 23',
  path: (
    <g fill="currentColor">
      <path d="M18 22.0025H2C0.9 22.0025 0 21.1025 0 20.0025C0 18.9025 0.9 18.0025 2 18.0025H18C19.1 18.0025 20 18.9025 20 20.0025C20 21.1025 19.1 22.0025 18 22.0025ZM11.06 3.1925L14.81 6.9425L6.04 15.7125C5.86 15.9025 5.6 16.0025 5.34 16.0025H3C2.45 16.0025 2 15.5525 2 15.0025V12.6625C2 12.3925 2.11 12.1425 2.29 11.9525L11.06 3.1925ZM15.88 5.8725L12.13 2.1225L13.96 0.2925C14.35 -0.0975 14.98 -0.0975 15.37 0.2925L17.71 2.6325C18.1 3.0225 18.1 3.6525 17.71 4.0425L15.88 5.8725Z" />
    </g>
  ),
});

export default React.memo(ManualMode);
