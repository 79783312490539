import React from 'react';
import PropTypes from 'prop-types';
import {
  chakra,
  ListItem,
  OrderedList,
  UnorderedList,
  Heading,
} from '@chakra-ui/react';

const BlockquoteStyle = {
  margin: '1.5em 10px',
  padding: '0.5em 10px',
};

export const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return (
        <chakra.blockquote
          style={BlockquoteStyle}
          borderLeftWidth="10px"
          borderLeftColor="gray.200"
          {...attributes}
        >
          {children}
        </chakra.blockquote>
      );
    case 'list-item':
      return <ListItem {...attributes}>{children}</ListItem>;
    case 'numbered-list':
      return <OrderedList {...attributes}>{children}</OrderedList>;
    case 'bulleted-list':
      return (
        <UnorderedList {...attributes} style={{ paddingLeft: '0.5rem' }}>
          {children}
        </UnorderedList>
      );
    case 'heading-one':
      return (
        <Heading as="h1" size="lg" {...attributes}>
          {children}
        </Heading>
      );
    case 'heading-two':
      return (
        <Heading as="h2" size="md" {...attributes}>
          {children}
        </Heading>
      );
    default:
      return <p {...attributes}>{children}</p>;
  }
};

Element.propTypes = {
  attributes: PropTypes.objectOf(PropTypes.any).isRequired,
  children: PropTypes.arrayOf(PropTypes.any).isRequired,
  element: PropTypes.objectOf(PropTypes.any).isRequired,
};
