import React, { useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Slate, withReact, Editable } from 'slate-react';
import { createEditor } from 'slate';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  useConst,
} from '@chakra-ui/react';
import { KubrickLogoIcon } from '@/components/icons';
import { Element } from '@/components/RichTextBlock/components/Element';
import { Leaf } from '@/components/RichTextBlock/components/Leaf';
import { useRouter } from 'next/router';
import { clearStorageCustom } from '@/utils/clearStorageUtils';

const title = 'New version available!';

const NewVersionModal = ({ isOpen, onClose, notification }) => {
  const initialFocusRef = useRef();
  const renderElement = useCallback(props => <Element {...props} />, []);
  const renderLeaf = useCallback(props => <Leaf {...props} />, []);
  const editor = useMemo(() => withReact(createEditor()), []);
  const router = useRouter();

  const handleOnConfirmation = async event => {
    const registrations = await navigator.serviceWorker.getRegistrations();

    await Promise.all(
      registrations.map(registration => registration.unregister()),
    );

    event.persist();

    clearStorageCustom();
    await onClose();

    if (!router.pathname.includes('settings')) {
      window.location.reload();
    }
  };

  const hoverStyleUpdateButton = useConst({ bg: 'gamma.600' });
  const activeStyleUpdateButton = useConst({ bg: 'gamma.700' });

  const focusStyle = useConst({ outline: 'transparent' });

  return (
    <Modal
      isOpen={isOpen}
      isCentered
      size="md"
      initialFocusRef={initialFocusRef}
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent ref={initialFocusRef} bg="white" borderTopRadius="4">
        <ModalHeader color="white" bg="alpha.900" borderTopRadius="4">
          <Flex alignItems="center" flexDirection="column" cursor="default">
            <KubrickLogoIcon w="120px" h="30px" />
            {title}
          </Flex>
        </ModalHeader>
        <ModalBody color="alpha.900" cursor="default" py="4">
          <Slate editor={editor} value={notification}>
            <Editable
              readOnly
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              spellCheck
              style={{
                minHeight: '5rem',
                maxHeight: '15rem',
                overflow: 'auto',
                marginLeft: '0.7rem',
              }}
            />
          </Slate>
        </ModalBody>
        <ModalFooter pt="0">
          <Button
            _focus={focusStyle}
            bg="gamma.500"
            onClick={handleOnConfirmation}
            textColor="white"
            variant="ghost"
            width="100%"
            _hover={hoverStyleUpdateButton}
            _active={activeStyleUpdateButton}
          >
            Update
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

NewVersionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  notification: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default NewVersionModal;
