import React from 'react';
import { createIcon } from '@chakra-ui/react';

const MooveitBrand = createIcon({
  displayName: 'Moove It Brand',
  path: (
    <path
      d="M17.262 10c0 1.021-1.417 2.434-3.383 4.406-1.127 1.13-2.754 2.798-4.225 2.798-1.503 0-2.376-1.01-2.376-2.295 0-1.166 1.094-2.205 1.663-2.806.987-1.042 1.568-1.525 1.568-2.103 0-.577-.581-1.06-1.568-2.102-.569-.602-1.663-1.64-1.663-2.807 0-1.474 1.145-2.294 2.256-2.294 1.052 0 2.038.484 4.345 2.797 1.966 1.972 3.383 3.385 3.383 4.406zM0 10c0-1.058 2.398-2.62 4.055-2.62 1.484 0 3.022 1.173 3.022 2.62s-1.538 2.62-3.022 2.62C2.398 12.62 0 11.058 0 10zm23.816 5.665C21.509 17.977 19.786 20 17.986 20c-.927 0-2.395-.555-2.395-2.54 0-1.166 1.128-2.433 1.698-3.035.986-1.042 3.401-3.183 3.401-4.425s-2.419-3.38-3.405-4.42c-.57-.603-1.694-1.874-1.694-3.04C15.59.556 17.059 0 17.987 0c1.798 0 3.522 2.022 5.829 4.335C25.782 6.308 27.893 8.62 27.893 10s-2.11 3.693-4.077 5.665zM62.258 2.597c4.723 0 7.888 2.961 7.888 7.403s-3.165 7.403-7.888 7.403c-4.758 0-7.926-2.961-7.926-7.403s3.168-7.403 7.926-7.403zm0 3.312c-2.272 0-3.787 1.636-3.787 4.091s1.515 4.09 3.787 4.09c2.254 0 3.771-1.635 3.771-4.09s-1.517-4.091-3.771-4.091zm17.04-3.312c4.724 0 7.891 2.961 7.891 7.403s-3.167 7.403-7.89 7.403c-4.759 0-7.926-2.961-7.926-7.403s3.167-7.403 7.926-7.403zm0 3.312c-2.271 0-3.786 1.636-3.786 4.091s1.515 4.09 3.787 4.09c2.254 0 3.77-1.635 3.77-4.09s-1.516-4.091-3.77-4.091zm13.81 11.274L87.142 2.766h4.275l3.556 9.372 3.515-9.372h4.275l-5.945 14.417h-3.71zm14.218-5.836c.27 1.445 1.56 2.72 3.562 2.72 1.663 0 3.193-.618 4.19-1.537l1.85 2.595c-1.278 1.35-3.771 2.27-6.31 2.27-4.611 0-7.69-2.88-7.69-7.25 0-4.503 2.896-7.54 7.282-7.54 4.361 0 7.075 2.776 7.075 7.096 0 .602-.05 1.156-.102 1.646h-9.857zm5.958-2.632c-.326-1.737-1.255-2.912-2.987-2.912-1.731 0-2.665 1.175-3.011 2.912h5.998zm10.67 8.445V2.767h4.002V17.16h-4.001zm5.766-14.394H142V6.08h-4.14v11.08h-4V6.08h-4.14V2.766zM48.214 9.102l-3.135 6.139h-2.714L39.25 9.126l-1.4 8.034h-3.955l2.798-14.392h3.284l3.744 8.302 3.747-8.302h3.284l2.797 14.392h-3.955l-1.38-8.057z"
      fill="currentColor"
      fillRule="evenodd"
    />
  ),
  viewBox: '50 -10 40 40',
});

export default React.memo(MooveitBrand);
