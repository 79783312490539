import React from 'react';
import { createIcon } from '@chakra-ui/react';

const ThreeDots = createIcon({
  displayName: 'ThreeDots',
  viewBox: '0 0 24 24',
  path: (
    <>
      <path
        d="M12 14.2502C13.2426 14.2502 14.25 13.2429 14.25 12.0002C14.25 10.7576 13.2426 9.75024 12 9.75024C10.7574 9.75024 9.75 10.7576 9.75 12.0002C9.75 13.2429 10.7574 14.2502 12 14.2502Z"
        fill="currentColor"
      />
      <path
        d="M12 21.7498C13.2426 21.7498 14.25 20.7424 14.25 19.4998C14.25 18.2571 13.2426 17.2498 12 17.2498C10.7574 17.2498 9.75 18.2571 9.75 19.4998C9.75 20.7424 10.7574 21.7498 12 21.7498Z"
        fill="currentColor"
      />
      <path
        d="M12 6.75C13.2426 6.75 14.25 5.74264 14.25 4.5C14.25 3.25736 13.2426 2.25 12 2.25C10.7574 2.25 9.75 3.25736 9.75 4.5C9.75 5.74264 10.7574 6.75 12 6.75Z"
        fill="currentColor"
      />
    </>
  ),
});

export default React.memo(ThreeDots);
