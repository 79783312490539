import getConfig from 'next/config';
import QueueLink from 'apollo-link-queue';
import { split, createHttpLink, ApolloClient } from '@apollo/client';
import { ApolloLink } from 'apollo-link';
import SerializingLink from 'apollo-link-serialize';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import { InMemoryCache } from '@apollo/client/cache';
import fetch from 'isomorphic-unfetch';
import { getMainDefinition } from '@apollo/client/utilities';
import { client } from 'websocket';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { GRAPHQL, SUBSCRIPTIONS } from '@/constants/paths';
import { NEW_LINE_CHAR } from '@/constants/global';
import errorsVar from '@/components/GraphQLProvider/errorsVar';
import timeEntryCreationVar from '@/components/GraphQLProvider/timeEntryCreationVar';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getFavoriteEntries: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        timeEntries: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        timeEntriesPagination: {
          merge(existing, incoming, { readField }) {
            const { userId: targetUserId } = timeEntryCreationVar();

            let timeEntries = existing ? [...existing] : [];

            if (targetUserId) {
              timeEntries = timeEntries.filter(timeEntry => {
                return readField('userId', timeEntry) === targetUserId;
              });
            }

            incoming.forEach(timeEntry => {
              let deletedIndex = -1;

              if (readField('delete', timeEntry)) {
                deletedIndex = timeEntries.findIndex(existingTimeEntry => {
                  return (
                    readField('id', existingTimeEntry) ===
                    readField('id', timeEntry)
                  );
                });
              }

              const index = timeEntries.findIndex(existingTimeEntry => {
                return (
                  readField('id', existingTimeEntry) ===
                  readField('id', timeEntry)
                );
              });

              if (
                !(readField('delete', timeEntry) && deletedIndex === -1) &&
                readField('finishedAt', timeEntry) !== null
              ) {
                if (deletedIndex !== -1) {
                  timeEntries.splice(deletedIndex, 1);
                } else if (index === -1) {
                  timeEntries.push(timeEntry);
                } else {
                  timeEntries[index] = timeEntry;
                }
              }
            });

            return timeEntries;
          },
        },
        timeEntriesReportPaginated: {
          keyArgs: ['input', ['filter', 'sortAttribute', 'sortDirection']],
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

const customFetchWithToken = idToken => (uri, options) => {
  const optionsWithHeader = {
    ...options,
    headers: { ...options.headers, Authorization: idToken },
  };

  return fetch(uri, optionsWithHeader);
};

export const queueLink = new QueueLink();

export const getApolloClient = idToken => {
  const { publicRuntimeConfig } = getConfig();

  const GRAPHQL_URL = `${publicRuntimeConfig.API_URL}${GRAPHQL}`;
  const GRAPHQL_WS_URL = `${publicRuntimeConfig.WS_URL}${SUBSCRIPTIONS}`;

  const retry = new RetryLink({ attempts: { max: Infinity } });

  const serializingLink = new SerializingLink();
  let linkSplit;

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      const errors = graphQLErrors.map(({ message }) => message);

      const errorsMessages = errors.join(NEW_LINE_CHAR);

      errorsVar(errorsMessages);
    }
  });

  const httpLink = createHttpLink({
    fetch: customFetchWithToken(idToken),
    uri: GRAPHQL_URL,
    headers: {
      'X-Frame-Options': 'SAMEORIGIN',
    },
  });

  if (idToken) {
    const wsLink = new GraphQLWsLink(
      createClient({
        url: GRAPHQL_WS_URL,
        lazy: true,
        webSocketImpl: client,
        connectionParams: {
          headers: {
            Authorization: idToken,
            'X-Frame-Options': 'SAMEORIGIN',
          },
        },
      }),
    );

    linkSplit = split(
      ({ query }) => {
        const definition = getMainDefinition(query);

        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      httpLink,
    );
  }

  const linkToUse = idToken ? linkSplit : httpLink;

  return new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      queueLink,
      serializingLink,
      retry,
      linkToUse,
    ]),
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
      },
    },
  });
};

export const getSsrApolloClient = idToken => {
  const { publicRuntimeConfig } = getConfig();

  const GRAPHQL_URL = `${publicRuntimeConfig.PRIVATE_API_URL}${GRAPHQL}`;

  return new ApolloClient({
    ssrMode: true,
    link: createHttpLink({
      fetch: customFetchWithToken(idToken),
      uri: GRAPHQL_URL,
    }),
    cache: new InMemoryCache(),
  });
};
