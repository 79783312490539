import { LIST_ENABLED_USERS } from '@/api/users';
import { useQuery } from '@apollo/client';

const useEnabledUsers = () => {
  const { data } = useQuery(LIST_ENABLED_USERS);
  const enabledUsers = data?.enabledUsers || [];

  return { enabledUsers };
};

export default useEnabledUsers;
