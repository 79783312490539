import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Arrow = createIcon({
  displayName: 'Arrow',
  path: (
    <g fill="currentColor" fillRule="evenodd">
      <rect x="0" y="3" width="7px" height="2px" />
      <polygon points="7 8, 7 0, 12 4" />
    </g>
  ),
  viewBox: '-6 -9 24 24',
});

export default React.memo(Arrow);
