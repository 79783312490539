import gql from 'graphql-tag';

const GET_USER_PERMISSIONS = gql`
  query($filter: PermissionFilterInput!) {
    getUserPermissions(filter: $filter) {
      id
      create
      read
      update
      delete
      scope
      additionalActions
    }
  }
`;

const GET_USER_PERMISSIONS_LIST = gql`
  query getUserPermissionsList($filter: [String!], $userId: Float) {
    apiPermissions: getUserPermissionsList(filter: $filter, userId: $userId) {
      entity
      create
      read
      update
      delete
      scope
      additionalActions
    }
  }
`;

const USER_PERMISSIONS_UPDATED_SUBSCRIPTION = gql`
  subscription UserPermissionsUpdated($filter: PermissionFilterInput!) {
    userPermissionsUpdated(filter: $filter) {
      id
      create
      read
      update
      delete
      scope
      additionalActions
    }
  }
`;

export {
  GET_USER_PERMISSIONS,
  USER_PERMISSIONS_UPDATED_SUBSCRIPTION,
  GET_USER_PERMISSIONS_LIST,
};
