import React from 'react';
import { createIcon } from '@chakra-ui/react';

const PlayButton = createIcon({
  displayName: 'PlayButton',
  path: (
    <g fill="none" fillRule="evenodd">
      <rect width="36" height="36" fill="currentColor" rx="18" />
      <path
        fill="#ffffff"
        d="M13 11.994c0-1.101.773-1.553 1.745-.997l10.51 6.005c.964.55.972 1.439 0 1.994l-10.51 6.007c-.964.55-1.745.102-1.745-.997V11.994z"
      />
    </g>
  ),
  viewBox: '0 0 36 36',
});

export default React.memo(PlayButton);
