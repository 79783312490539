import React from 'react';
import { createIcon } from '@chakra-ui/icons';

const UpdateArrowsCircle = createIcon({
  displayName: 'UpdateArrowsCircle',
  viewBox: '0 0 24 25',
  path: (
    <path
      fill="currentColor"
      d="M12 4.50003V2.71003C12 2.26003 11.46 2.04003 11.15 2.36003L8.35 5.15003C8.15 5.35003 8.15 5.66003 8.35 5.86003L11.14 8.65003C11.46 8.96003 12 8.74003 12 8.29003V6.50003C15.31 6.50003 18 9.19003 18 12.5C18 13.29 17.85 14.06 17.56 14.75C17.41 15.11 17.52 15.52 17.79 15.79C18.3 16.3 19.16 16.12 19.43 15.45C19.8 14.54 20 13.54 20 12.5C20 8.08003 16.42 4.50003 12 4.50003ZM12 18.5C8.69 18.5 6 15.81 6 12.5C6 11.71 6.15 10.94 6.44 10.25C6.59 9.89003 6.48 9.48003 6.21 9.21003C5.7 8.70003 4.84 8.88003 4.57 9.55003C4.2 10.46 4 11.46 4 12.5C4 16.92 7.58 20.5 12 20.5V22.29C12 22.74 12.54 22.96 12.85 22.64L15.64 19.85C15.84 19.65 15.84 19.34 15.64 19.14L12.85 16.35C12.54 16.04 12 16.26 12 16.71V18.5Z"
    />
  ),
});

export default React.memo(UpdateArrowsCircle);
