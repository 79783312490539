import React from 'react';
import { createIcon } from '@chakra-ui/react';

const TimeEntriesOpen = createIcon({
  displayName: 'TimeEntriesOpen',
  viewBox: '0 0 12 8',
  path: (
    <path
      d="M6 0.5L0 6.5L1.41 7.91L6 3.33L10.59 7.91L12 6.5L6 0.5Z"
      fill="currentColor"
    />
  ),
});

export default React.memo(TimeEntriesOpen);
