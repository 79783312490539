import { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import {
  GET_USER_PERMISSIONS_LIST,
  USER_PERMISSIONS_UPDATED_SUBSCRIPTION,
} from '@/api/usersPermission';
import {
  LOCK_ENTRIES,
  MANAGE_BILLABLE_ENTRIES,
  CHANGE_ROLES,
  MANAGE_DISABLED_ENTRIES_PERMISSION,
} from '@/constants/permissions';
import { USER_ROLE } from '@/api/roles';

const usePermissions = () => {
  const {
    data,
    loading: loadingPermissions,
    refetch: refetchPermissions,
    subscribeToMore: userPermissionsSubscribe,
  } = useQuery(GET_USER_PERMISSIONS_LIST, {
    variables: { filter: [] },
  });

  const { data: userRoleData, refetch: refetchRole } = useQuery(USER_ROLE);

  const userPermissionsList = data?.apiPermissions || [];

  const userPermissions = userPermissionsList.reduce(
    (
      acc,
      {
        entity,
        create,
        read,
        update,
        delete: deletePermission,
        scope,
        additionalActions,
      },
    ) => {
      acc[entity] = {
        create,
        read,
        update,
        delete: deletePermission,
        scope,
        additionalActions,
      };

      return acc;
    },
    {},
  );

  const additionalActionsPermissions = userPermissionsList.reduce(
    (acc, { additionalActions }) => {
      if (additionalActions) {
        additionalActions.split(',').forEach(action => {
          const trimmedAction = action.trim();

          if (!acc.includes(trimmedAction)) {
            acc.push(trimmedAction);
          }
        });
      }

      return acc;
    },
    [''],
  );

  useEffect(() => {
    userPermissionsSubscribe({
      document: USER_PERMISSIONS_UPDATED_SUBSCRIPTION,
      variables: { filter: { entity: 'timeEntry' } },
      updateQuery: (prev, { subscriptionData }) => {
        if (!prev || !subscriptionData.data) {
          return prev;
        }

        const newPermissions = subscriptionData.data.userPermissionsUpdated;

        refetchRole();

        return { ...prev, getUserPermissions: newPermissions };
      },
    });
  }, []);

  const permissionToLockEntries = additionalActionsPermissions?.includes(
    LOCK_ENTRIES,
  );
  const permissionToManageBillableEntries = additionalActionsPermissions?.includes(
    MANAGE_BILLABLE_ENTRIES,
  );
  const permissionToChangeRoles = additionalActionsPermissions?.includes(
    CHANGE_ROLES,
  );

  const permissionToManageDisabledEntries = additionalActionsPermissions?.includes(
    MANAGE_DISABLED_ENTRIES_PERMISSION,
  );

  const userRole = (userRoleData && userRoleData.userRole) || {};

  return {
    loadingPermissions,
    permissionToLockEntries,
    permissionToManageBillableEntries,
    permissionToManageDisabledEntries,
    permissionToChangeRoles,
    refetchPermissions,
    userRole,
    userPermissions,
    additionalActionsPermissions,
  };
};

export default usePermissions;
