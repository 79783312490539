const START_NEW_TIME_ENTRY = 'n';
const STOP_CURRENT_TIME_ENTRY = 's';
const SWITCH_BETWEEN_TIMER_MODES = 'm';
const OPEN_SHORTCUTS_MODAL = 'meta+shift +/';
const START_FAVORITE = '1...9,0';

export {
  START_NEW_TIME_ENTRY,
  STOP_CURRENT_TIME_ENTRY,
  SWITCH_BETWEEN_TIMER_MODES,
  OPEN_SHORTCUTS_MODAL,
  START_FAVORITE,
};
