import React, { useState, useEffect, useRef } from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import timeEntryCreationVar from '@/components/GraphQLProvider/timeEntryCreationVar';
import { getDiff, joinDateTimeToDate } from '@/utils/dateUtils';
import { INITIAL_TIME } from '@/constants/global';

const Meta = props => {
  const {
    title,
    charSet,
    viewport,
    description,
    ogType,
    ogTitle,
    ogDescription,
    ogSiteName,
    ogUrl,
    ogImage,
    twitterCard,
    twitterDescription,
    twitterSite,
    twitterCreator,
    twitterImage,
    children,
  } = props;

  const [elapsedTime, setElapsedTime] = useState(INITIAL_TIME);

  const timeEntryCache = useReactiveVar(timeEntryCreationVar);

  const { finishedAt, description: timeEntryDescription, startedAt, date } =
    timeEntryCache ?? {};

  const prevStartedAtRef = useRef(startedAt);

  const isATimeEntryPlaying = finishedAt === null;

  const updateElapsedTime = newStartedAt => {
    const formatStart = joinDateTimeToDate(date, newStartedAt);

    setElapsedTime(getDiff(new Date(formatStart), new Date()));
  };

  const countUp = () => {
    updateElapsedTime(startedAt);
  };

  useEffect(() => {
    setElapsedTime(INITIAL_TIME);
    if (isATimeEntryPlaying) {
      const interval = setInterval(countUp, 1000);

      return () => {
        clearInterval(interval);
      };
    }

    if (prevStartedAtRef.current !== startedAt) {
      prevStartedAtRef.current = startedAt;
      updateElapsedTime(startedAt);
    }

    return undefined;
  }, [isATimeEntryPlaying, startedAt, date]);

  const timeEntryTitle = `${elapsedTime} ${timeEntryDescription} - Kubrick`;

  return (
    <Head>
      <title>{isATimeEntryPlaying ? timeEntryTitle : title}</title>
      <link
        rel="shortcut icon"
        href={
          isATimeEntryPlaying
            ? '/static/favicon-coral.svg'
            : '/static/favicon-gray.svg'
        }
      />
      <meta charSet={charSet} />
      <meta name="viewport" content={viewport} />
      {description && <meta name="description" content={description} />}
      {ogType && <meta property="og:type" content={ogType} />}
      {ogTitle && (
        <meta name="og:title" property="og:title" content={ogTitle} />
      )}
      {ogDescription && (
        <meta
          name="og:description"
          property="og:description"
          content={ogDescription}
        />
      )}
      {ogSiteName && <meta property="og:site_name" content={ogSiteName} />}
      {ogUrl && <meta property="og:url" content={ogUrl} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {twitterCard && <meta name="twitter:card" content={twitterCard} />}
      {twitterDescription && (
        <meta name="twitter:description" content={twitterDescription} />
      )}
      {twitterSite && <meta name="twitter:site" content={twitterSite} />}
      {twitterCreator && (
        <meta name="twitter:creator" content={twitterCreator} />
      )}
      {twitterImage && <meta name="twitter:image" content={twitterImage} />}
      {children}
    </Head>
  );
};

Meta.defaultProps = {
  title: 'Kubrick',
  charSet: 'utf-8',
  viewport: 'width=device-width,minimum-scale=1,initial-scale=1',
};

Meta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  charSet: PropTypes.string,
  viewport: PropTypes.string,
  ogType: PropTypes.oneOf([
    'music.song',
    'music.album',
    'music.playlist',
    'music.radio_station',
    'video.movie',
    'video.episode',
    'video.tv_show',
    'video.other',
    'article',
    'book',
    'profile',
    'website',
  ]),
  ogTitle: PropTypes.string,
  ogDescription: PropTypes.string,
  ogSiteName: PropTypes.string,
  ogUrl: PropTypes.string,
  ogImage: PropTypes.string,
  twitterCard: PropTypes.oneOf([
    'summary',
    'summary_large_image',
    'player',
    'app',
    'og:type',
  ]),
  twitterDescription: PropTypes.string,
  twitterSite: PropTypes.string,
  twitterCreator: PropTypes.string,
  twitterImage: PropTypes.string,
  children: PropTypes.node,
};

export default Meta;
