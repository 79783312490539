export const KUBRICK_PALETTE = [
  '#E15141',
  '#D63964',
  '#4697EB',
  '#48A8ED',
  '#52B9D0',
  '#67AB5B',
  '#97BF5C',
  '#D0D859',
  '#F5C044',
  '#F19C38',
  '#EC6237',
  '#9E9E9E',
];

export const HEADER_BACKGROUND_COLOR = {
  light: 'alpha.900',
  dark: 'gray.900',
};

export const HEADER_ICONS_COLOR = {
  light: 'delta.500',
  dark: 'alpha.400',
};

export const KUBRICK_LOGO_COLOR = {
  light: 'white',
  dark: 'alpha.400',
};

export const COMPANY_LOGO_COLOR = {
  light: 'gray.800',
  dark: 'white',
};

export const TIME_ENTRY_BACKGROUND_COLOR = {
  light: 'white',
  dark: 'gray.800',
};

export const USER_GROUPS_BACKGROUND_COLOR = {
  light: 'white',
  dark: 'gray.800',
};

export const SHOW_ACCORDION_USER_GROUPS_BACKGROUND_COLOR = {
  light: 'alpha.100',
  dark: 'alpha.700',
};

export const EDIT_ICON_COLOR = {
  light: 'alpha.700',
  dark: 'alpha.500',
};

export const ERROR_PAGE_BACKGROUND_COLOR = {
  light: 'gray.50',
  dark: 'gray.800',
};

export const REPORT_SUMMARY_SECOND_LIST_BACKGROUND_COLOR = {
  light: 'gray.100',
  dark: 'gray.900',
};

export const NAVBAR_ITEM_COLOR = {
  light: 'alpha.900',
  dark: 'alpha.200',
};

export const NAVBAR_ITEM_BORDER_COLOR = {
  light: 'gray.300',
  dark: 'alpha.600',
};

export const CURRENT_NAVBAR_ITEM_BACKGROUND_COLOR = {
  light: 'alpha.100',
  dark: 'alpha.600',
};

export const CURRENT_NAVBAR_ITEM_BORDER_COLOR = {
  light: 'alpha.100',
  dark: 'alpha.600',
};

export const CLEAR_FILTERS_BUTTON_ICON_COLOR = {
  light: 'alpha.500',
  dark: 'alpha.100',
};

export const NO_GROUPS_MESSAGE = {
  light: 'alpha.500',
  dark: 'alpha.100',
};

export const CLEAR_FILTERS_BUTTON_BACKGROUND_COLOR = {
  light: 'alpha.100',
  dark: 'alpha.600',
};

export const CLEAR_FILTERS_BUTTON_BACKGROUND_HOVER_COLOR = {
  light: 'gray.50',
  dark: 'gray.700',
};

export const TIME_ENTRY_BORDER_COLOR = {
  light: 'gray.100',
  dark: 'gray.900',
};

export const SELECTED_OPTION_BACKGROUND_COLOR = {
  light: 'gray.100',
  dark: 'gray.500',
};

export const FOCUSED_OPTION_BACKGROUND_COLOR = {
  light: 'gray.50',
  dark: 'gray.600',
};

export const PROJECT_AND_TAG_ICONS_IN_USE_BACKGROUND_COLOR = {
  light: 'alpha.100',
  dark: 'alpha.700',
};

export const PROJECT_AND_TAG_ICONS_BACKGROUND_COLOR = {
  light: 'alpha.500',
  dark: 'alpha.400',
};

export const PROJECT_AND_TAG_POPOVER_BACKGROUND_COLOR = {
  light: 'white',
  dark: 'gray.900',
};

export const PROJECT_AND_TAG_POPOVER_BORDER_COLOR = {
  light: 'gray.100',
  dark: 'gray.600',
};

export const BUTTON_TEXT_COLOR = {
  light: 'black',
  dark: 'white',
};

export const LABEL_TEXT_COLOR = {
  light: 'black',
  dark: 'white',
};

export const CLOSE_ICON_COLOR = {
  light: 'black',
  dark: 'white',
};

export const TIME_ENTRY_HOVER_BACKGROUND_COLOR = {
  light: 'gray.100',
  dark: 'gray.600',
};

export const TIME_ENTRY_IS_CHECKED_BACKGROUND_COLOR = {
  light: 'alpha.50',
  dark: 'alpha.800',
};

export const INPUT_FOCUS_BORDER_COLOR = {
  light: 'alpha.300',
  dark: 'alpha.400',
};

export const CALENDAR_BACKGROUND_COLOR = {
  light: 'white',
  dark: 'gray.900',
};

export const CALENDAR_INPUT_COLOR = {
  light: 'alpha.500',
  dark: 'alpha.300',
};

export const DATE_RANGE_SELECTOR_BACKGROUND_COLOR = {
  light: 'alpha.50',
  dark: 'gray.800',
};

export const DATE_RANGE_SELECTOR_BORDER_COLOR = {
  light: 'gray.300',
  dark: 'gray.600',
};

export const TAG_DISPLAY_HOVER_COLOR = {
  light: 'gray.600',
  dark: 'gray.200',
};

export const TAG_DISPLAY_BACKGROUND_COLOR = {
  light: 'alpha.100',
  dark: 'alpha.200',
};

export const TAG_LIST_HOVER_COLOR = {
  light: 'alpha.100',
  dark: 'alpha.700',
};

export const TAG_RADIO_COLOR = {
  light: 'alpha.200',
  dark: 'alpha.400',
};

export const TIME_ENTRY_SECONDARY_TEXT_COLOR = {
  light: 'black',
  dark: 'white',
};

export const FILTER_SEARCH_MESSAGE_TEXT_COLOR = {
  light: 'gray.500',
  dark: 'white',
};

export const REPORT_FILTER_BUTTON_TEXT_COLOR = {
  light: 'alpha.900',
  dark: 'gray.300',
};

export const REPORT_FILTER_HIGHLIGHTED_BUTTON_ICON_COLOR = {
  light: 'beta.500',
  dark: 'beta.600',
};

export const REPORT_FILTER_HOVER_AND_FOCUS_BUTTON_ICON_AND_TEXT_COLOR = {
  light: 'beta.500',
  dark: 'beta.600',
};

export const REPORT_FILTER_HOVER_AND_FOCUS_BUTTON_BACKGROUND_COLOR = {
  light: 'beta.50',
  dark: 'beta.200',
};

export const REPORT_FILTER_BUTTON_CIRCLE_WITH_COUNTER_BACKGROUND_COLOR = {
  light: 'alpha.900',
  dark: 'white',
};

export const REPORT_FILTER_BUTTON_CIRCLE_WITH_COUNTER_TEXT_COLOR = {
  light: 'white',
  dark: 'beta.600',
};

export const REPORT_FILTER_ALL_NONE_BUTTONS_TEXT_COLOR = {
  light: 'alpha.500',
  dark: 'alpha.200',
};

export const BORDER_COLOR = {
  light: 'gray.50',
  dark: 'gray.700',
};

export const BLOCKED_ENTRIES_MESSAGE_BACKGROUND = {
  light: 'gray.100',
  dark: 'gray.800',
};

export const BLOCKED_ENTRIES_TEXT_COLOR = {
  light: 'gray.400',
  dark: 'gray.400',
};

export const BLOCKED_ENTRIES_MESSAGE_BORDER_COLOR = {
  light: 'gray.100',
  dark: 'gray.700',
};

export const COMMUNICATION_TAG_COLOR = {
  light: 'alpha.100',
  dark: 'alpha.200',
};

export const DEVELOPMENT_TAG_COLOR = {
  light: 'beta.100',
  dark: 'beta.200',
};

export const BUG_TAG_COLOR = {
  light: 'gamma.100',
  dark: 'gamma.200',
};

export const CODE_REVIEW_TAG_COLOR = {
  light: 'delta.100',
  dark: 'delta.200',
};

export const QA_TAG_COLOR = {
  light: 'epsilon.100',
  dark: 'epsilon.200',
};

export const INFRASTRUCTURE_TAG_COLOR = {
  light: 'zeta.100',
  dark: 'zeta.200',
};

export const UX_UI_TAG_COLOR = {
  light: 'gray.100',
  dark: 'gray.200',
};

export const TICK_BUTTON_COLOR = {
  light: 'alpha.500',
  dark: 'alpha.400',
};

export const TICK_BUTTON_HOVER_COLOR = {
  light: 'alpha.900',
  dark: 'alpha.300',
};

export const TICK_BUTTON_ACTIVE_COLOR = {
  light: 'alpha.700',
  dark: 'alpha.800',
};

export const PLAY_BUTTON_COLOR = {
  light: 'delta.500',
  dark: 'delta.600',
};

export const PLAY_BUTTON_HOVER_ACTIVE_COLOR = {
  light: 'delta.900',
  dark: 'delta.500',
};

export const STOP_BUTTON_COLOR = {
  light: 'beta.500',
  dark: 'beta.700',
};

export const STOP_BUTTON_HOVER_ACTIVE_COLOR = {
  light: 'beta.900',
  dark: 'beta.500',
};

export const MODE_BUTTON_COLOR = {
  light: 'alpha.500',
  dark: 'alpha.400',
};

export const MODE_BUTTON_DISABLE_COLOR = {
  light: 'gray.500',
  dark: 'gray.400',
};

export const TIME_ENTRY_DESCRIPTION_COLOR = {
  light: 'alpha.100',
  dark: 'alpha.700',
};

export const NAVIGATION_BACKGROUND_COLOR = {
  light: 'white',
  dark: 'gray.800',
};

export const NAVIGATION_MENU_ITEM_COLOR = {
  light: 'alpha.900',
  dark: 'alpha.400',
};

export const NAVIGATION_MENU_ITEM_TITLE_COLOR = {
  light: 'alpha.900',
  dark: 'white',
};

export const NAVIGATION_MENU_ITEM_BACKGROUND_HOVER_COLOR = {
  light: 'gray.50',
  dark: 'gray.700',
};

export const CURRENT_NAVIGATION_MENU_ITEM_BACKGROUND_COLOR = {
  light: 'alpha.100',
  dark: 'alpha.600',
};

export const CURRENT_NAVIGATION_MENU_ITEM_COLOR = {
  light: 'alpha.900',
  dark: 'alpha.200',
};

export const NAVIGATION_DIVIDER_BACKGROUND_COLOR = {
  light: 'gray.200',
  dark: 'whiteAlpha.300',
};

export const BULK_AND_CHEVRON_BUTTON_COLOR = {
  light: 'alpha.500',
  dark: 'alpha.400',
};

export const BULK_BUTTON_HOVER_COLOR = {
  light: 'alpha.100',
  dark: 'alpha.700',
};

export const PROJECT_TAG_POPOVER_COLOR = {
  light: 'white',
  dark: 'gray.800',
};

export const PROJECT_TAG_POPOVER_BORDER_COLOR = {
  light: 'gray.100',
  dark: 'gray.600',
};

export const TAG_POPOVER_ITEM_HOVER_COLOR = {
  light: 'alpha.100',
  dark: 'alpha.700',
};

export const TAG_POPOVER_ITEM_RADIO_COLOR = {
  light: 'alpha.200',
  dark: 'alpha.400',
};

export const CONFIRMATION_MODAL_BACKGROUND_COLOR = {
  light: 'white',
  dark: 'gray.800',
};

export const CONFIRMATION_MODAL_HEADER_COLOR = {
  light: 'alpha.500',
  dark: 'white',
};

export const CONFIRMATION_MODAL_BODY_COLOR = {
  light: 'gray.500',
  dark: 'gray.200',
};

export const CONFIRMATION_MODAL_BORDER_COLOR = {
  light: 'transparent',
  dark: 'gray.600',
};

export const CONFIRMATION_MODAL_BUTTON_BACKGROUND_COLOR = {
  light: 'white',
  dark: 'gray.900',
};

export const CONFIRMATION_DEFAULT_MODAL_BUTTON_TEXT_AND_BORDER_COLOR = {
  light: 'alpha.500',
  dark: 'alpha.300',
};

export const CONFIRMATION_RED_MODAL_BUTTON_TEXT_AND_BORDER_COLOR = {
  light: 'epsilon.500',
  dark: 'epsilon.300',
};

export const CONFIRMATION_GREEN_MODAL_BUTTON_TEXT_AND_BORDER_COLOR = {
  light: 'gamma.500',
  dark: 'gamma.300',
};

export const WARNING_MESSAGE_BACKGROUND_COLOR = {
  light: 'epsilon.50',
  dark: 'gray.800',
};

export const WARNING_MESSAGE_BORDER_COLOR = {
  light: 'epsilon.400',
  dark: 'epsilon.600',
};

export const WARNING_MESSAGE_TEXT_COLOR = {
  light: 'epsilon.400',
  dark: 'epsilon.500',
};

export const TIME_STATS_TEXT_COLOR = {
  light: 'alpha.700',
  dark: 'white',
};

export const getColorFromPalette = index => {
  const { length } = KUBRICK_PALETTE;

  return KUBRICK_PALETTE[index % length];
};

export const TAGS_TEXT_COLOR = {
  '#E9E9FF': 'alpha.600',
  '#FCE4EF': 'beta.800',
  '#C6F6D5': 'gamma.800',
  '#D6FAFF': 'delta.700',
  '#FED7D7': 'epsilon.800',
  '#FEFCBF': 'zeta.800',
  '#EDF2F7': 'gray.800',
};

export const FAVORITES_TEXT_COLOR = {
  '#CBD5E0': 'gray.800',
  '#A0AEC0': 'gray.700',
  '#718096': 'gray.200',
  '#CBCBEB': 'alpha.900',
  '#ADADE0': 'alpha.800',
  '#9191DF': 'alpha.700',
  '#8080E9': 'alpha.200',
  '#F5ADD0': 'beta.900',
  '#F189BB': 'beta.800',
  '#ED64A6': 'beta.700',
  '#DE3285': 'beta.200',
  '#9AE6B4': 'gamma.900',
  '#68D391': 'gamma.800',
  '#48BB78': 'gamma.700',
  '#38A169': 'gamma.200',
  '#8CE1ED': 'delta.900',
  '#14B6CD': 'delta.800',
  '#1095A8': 'delta.200',
  '#FEB2B2': 'epsilon.900',
  '#FC8181': 'epsilon.800',
  '#F56565': 'epsilon.700',
  '#E53E3E': 'epsilon.300',
  '#C53030': 'epsilon.200',
  '#F6E05E': 'zeta.900',
  '#ECC94B': 'zeta.800',
  '#D69E2E': 'zeta.700',
  '#B7791F': 'zeta.300',
};

export const DROPDOWN_BACKGROUND_COLOR = {
  light: 'white',
  dark: 'gray.800',
};

export const DROPDOWN_BORDER_COLOR = {
  light: 'gray.200',
  dark: 'gray.400',
};

export const DROPDOWN_SELECTED_OPTION_BACKGROUND_COLOR = {
  light: 'alpha.100',
  dark: 'alpha.600',
};

export const REPORT_PAGE_DATE_SELECTION_CONTAINER_BACKGROUND_COLOR = {
  light: 'alpha.100',
  dark: 'gray.900',
};

export const POPOVER_BACKGROUND_COLOR = {
  light: 'white',
  dark: 'gray.900',
};

export const GROUP_ITEM_HOVER_COLOR = {
  light: 'alpha.100',
  dark: 'alpha.700',
};

export const INFO_BADGE_BACKGROUND_COLOR = {
  light: 'gray.200',
  dark: 'gray.500',
};

export const INFO_BADGE_TEXT_COLOR = {
  light: 'black',
  dark: 'white',
};

export const TIME_ENTRY_PLAY_BUTTON_COLOR = {
  light: 'alpha.500',
  dark: 'alpha.400',
};

export const FAVORITE_ENTRY_POPOVER_HEADER_COLOR = {
  light: 'gray.500',
  dark: 'gray.100',
};

export const FAVORITES_BACKGROUND_COLOR = {
  light: 'white',
  dark: 'gray.800',
};

export const DOWNLOAD_REPORT_CSV_BUTTON_COLOR = {
  light: 'alpha.500',
  dark: 'gray.300',
};

export const PREFERENCE_TITLE_COLOR = {
  light: 'alpha.900',
  gray: 'gray.300',
};

export const GROUPS_TITLE_COLOR = {
  light: 'gray.900',
  dark: 'gray.300',
};

export const GROUPS_TEXT_COLOR = {
  light: 'gray.700',
  dark: 'gray.300',
};

export const PREFERENCE_SUBTITLE_COLOR = {
  light: 'gray.600',
  dark: 'gray.600',
};

export const SOLID_BUTTON_BACKGROUND_COLOR = {
  light: 'alpha.500',
  dark: 'alpha.600',
};

export const GHOST_BUTTON_COLOR = {
  light: 'alpha.500',
  dark: 'alpha.200',
};

export const ZERO_STATE_TEXT_COLOR = {
  light: 'alpha.900',
  dark: 'white',
};

export const COMMON_TIME_ENTRIES_BG = {
  light: 'white',
  dark: 'gray.800',
};

export const COMMON_TIME_ENTRIES_DESCRIPTION = {
  light: 'alpha.500',
  dark: 'alpha.400',
};

export const COMMON_TIME_ENTRIES_FOCUS_BG = {
  light: 'gray.50',
  dark: 'alpha.700',
};

export const MEMBERS_SYNC_BUTTON_COLOR = {
  light: 'alpha.500',
  dark: 'alpha.400',
};

export const OVERLAPPED_TIME_ENTRIES_COLOR = {
  light: 'epsilon.500',
  dark: 'epsilon.600',
};

export const OVERLAPPED_TIME_ENTRIES_TOOLTIP_BG = {
  light: 'gray.700',
  dark: 'gray.500',
};

export const SELECT_USER_BUTTON_HOVER_OPEN = {
  light: 'alpha.900',
  dark: 'alpha.300',
};

export const SELECT_USER_BUTTON_HOVER_CLOSED = {
  light: 'gray.100',
  dark: 'whiteAlpha.300',
};

export const SELECT_USER_BUTTON_COLOR = {
  light: 'alpha.700',
  dark: 'alpha.400',
};

export const SELECT_USER_ICON_OPEN_STROKE = {
  light: 'alpha.500',
  dark: 'alpha.400',
};

export const SELECT_USER_ICON_CLOSED_STROKE = {
  light: 'white',
  dark: 'gray.900',
};

export const SELECT_USER_BORDER_COLOR = {
  light: 'gray.100',
  dark: 'gray.100',
};

export const SELECT_USER_BANNER_COLOR = {
  light: 'alpha.600',
  dark: 'alpha.600',
};

export const SELECT_USER_BANNER_BG = {
  light: 'alpha.50',
  dark: 'alpha.200',
};

export const MODAL_HEADER_COLOR = {
  light: 'alpha.500',
  dark: 'white',
};

export const SHORTCUTS_MODAL_BUTTON_BORDER_COLOR = {
  light: 'gray.500',
  dark: 'alpha.600',
};

export const SHORTCUTS_MODAL_BUTTON_TEXT_COLOR = {
  light: 'gray.500',
  dark: 'alpha.200',
};
