import { extendTheme } from '@chakra-ui/react';
import { MultiSelectTheme } from 'chakra-multiselect';
import colors from './colors';

const theme = extendTheme({
  styles: {
    global: props => ({
      'html, body': {
        backgroundColor: props.colorMode === 'dark' ? 'gray.900' : 'alpha.50',
      },
      'input[type=time]::-webkit-inner-spin-button': {
        webkitAppearance: 'none',
      },
      'input[type=time]::-webkit-clear-button': {
        webkitAppearance: 'none',
      },
      'input[type=time]': {
        padding: '2px',
      },
      '.js-focus-visible :focus:not([data-focus-visible-added])': {
        outline: 'none',
        boxShadow: 'none',
      },
      '.rdp': {
        marginBottom: '0',
      },
      '.rdp-caption': {
        marginBottom: '.5rem',
      },
      '.rdp-caption_label': {
        color: props.colorMode === 'dark' ? 'alpha.300' : 'alpha.500',
        fontWeight: '700 !important',
        fontSize: '1.15em',
      },
      '.rdp-nav_button': {
        marginLeft: '10px',
        width: '24px',
        height: '24px',
        color: 'alpha.900',
        backgroundColor: props.colorMode === 'dark' ? 'alpha.300' : 'alpha.100',
      },
      '.rdp-button:focus-visible:not([disabled])': {
        color: 'alpha.900',
        backgroundColor: props.colorMode === 'dark' ? 'alpha.300' : 'alpha.100',
        border: `1.5px solid ${
          props.colorMode === 'dark' ? 'white' : '#3E3EA1'
        }`,
      },
      '.rdp-button:hover:not([disabled]):not(.rdp-day_selected)': {
        opacity: '.8',
      },
      '.rdp-nav_button > svg': {
        width: '9px',
        height: '9px',
      },
      '.rdp-month': {
        display: 'block',
      },
      '.rdp-head_row': {
        color: '#8b9898',
      },
      '.rdp-head_cell': {
        textTransform: 'none',
        fontSize: '.875em',
        fontWeight: '400',
        width: '35px',
        height: '35px',
      },
      '.rdp-day': {
        color: props.colorMode === 'dark' ? 'alpha.300' : 'alpha.500',
        paddingY: '1.5',
        border: 0,
        borderRadius: '100%',
      },
      '.rdp-day_today': {
        color: props.colorMode === 'dark' ? 'alpha.600' : 'alpha.900',
      },
      '.rdp-day_selected': {
        borderRadius: '100%',
      },
      '.rdp-day_selected:focus-visible': {
        outlineColor: props.colorMode === 'dark' ? 'alpha.600' : 'alpha.400',
      },
      '.rdp-day_selected:focus-visible:not([disabled])': {
        color: props.colorMode === 'dark' ? 'alpha.200' : 'alpha.100',
      },
      '.rdp-day_range_middle': {
        borderRadius: '0%',
      },
      '.rdp-day:hover:not(.DayPicker-Day--weekly, .rdp-day_selected, .rdp-day_outside, .rdp-day_disabled)': {
        backgroundColor:
          props.colorMode === 'dark'
            ? `${colors.alpha[300]} !important`
            : `${colors.alpha[400]} !important`,
        color: 'white',
      },
      '.rdp-day_selected:not(.rdp-day_range_start):not(.rdp-day_range_end):not(.rdp-day_outside)': {
        backgroundColor:
          props.colorMode === 'dark'
            ? `${colors.alpha[300]} !important`
            : `${colors.alpha[500]} !important`,
        color: props.colorMode === 'dark' ? 'alpha.500' : 'white',
      },
      '.rdp-day_range_start:not(.rdp-day_outside)': {
        borderTopLeftRadius: '30% !important',
        borderBottomLeftRadius: '30% !important',
        borderTopRightRadius: '0% !important',
        borderBottomRightRadius: '0% !important',
        backgroundColor:
          props.colorMode === 'dark'
            ? `${colors.alpha[500]} !important`
            : `${colors.alpha[800]} !important`,
        color: 'white',
      },
      '.rdp-day_range_end:not(.rdp-day_outside)': {
        borderTopLeftRadius: '0% !important',
        borderBottomLeftRadius: '0% !important',
        borderTopRightRadius: '30% !important',
        borderBottomRightRadius: '30% !important',
        backgroundColor:
          props.colorMode === 'dark'
            ? `${colors.alpha[500]} !important`
            : `${colors.alpha[800]} !important`,
        color: 'white',
      },
      '#filterTypeAheadTooltip': {
        minWidth: 'max-content',
        fontSize: '11px',
        padding: '4px',
      },
    }),
  },

  colors: {
    gray: colors.alpha, // CSS Reset uses gray constant to define the light and dark modes
    ...colors,
  },

  components: {
    Button: {
      baseStyle: { focus: 'none' },
    },
    Checkbox: {
      defaultProps: {
        colorScheme: 'alpha',
      },
    },
    CheckboxGroup: {
      defaultProps: {
        colorScheme: 'alpha',
      },
    },
    Tooltip: {
      variants: {
        'blocked-entry': {
          bg: colors.gray[500],
          borderRadius: '8px',
        },
      },
    },
    Table: {
      baseStyle: {
        td: {
          cursor: 'default',
        },
        th: {
          cursor: 'default',
        },
        tr: {
          cursor: 'default',
        },
      },
    },
    Switch: {
      baseStyle: {
        track: {
          _checked: {
            background: colors.alpha[500],
          },
          _focus: {
            boxShadow: 'none',
          },
        },
      },
    },
    MultiSelect: MultiSelectTheme,
  },

  gradients: {
    base: `linear-gradient(150deg, ${colors.alpha[900]} 15%, ${
      colors.delta[900]
    } 85%)`,
  },

  boxShadow: {
    xs: '0px 0px 0px 1px rgba(45, 55, 72, 0.06)',
    sm: '0px 1px 2px rgba(0, 0, 0, 0.05)',
    base: '0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)',
    md:
      '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg:
      '0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl:
      '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0px 25px 50px -12px rgba(0, 0, 0, 0.25)',
    outline: '0px 0px 0px 3px rgba(63, 153, 225, 0.6)',
    inner: 'inset 0px 2px 4px rgba(0, 0, 0, 0.06)',
    'dark-lg':
      '0px 0px 0px 1px rgba(0, 0, 0, 0.1), 0px 5px 10px rgba(0, 0, 0, 0.2), 0px 15px 40px rgba(0, 0, 0, 0.4)',
  },

  sizes: {
    '0.5xs': '20rem',
    '1xs': '18rem',
    '2xs': '16rem',
    '3xs': '14rem',
    '4xs': '12rem',
    '5xs': '10rem',
    '6xs': '8rem',
    '7xs': '6rem',
    '7.5xs': '5.5rem',
    '8xs': '5rem',
    '9xs': '4rem',
    '10xs': '3rem',
    '11xs': '2rem',
  },

  fontSize: {
    '2xs': '10px',
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '30px',
    '4xl': '36px',
    '5xl': '48px',
    '6xl': '60px',
  },

  shadows: {
    outline: `0 0 0 1px ${colors.alpha[400]}`,
  },
});

export default theme;
