import React from 'react';
import { createIcon } from '@chakra-ui/react';

const MooveitLogo = createIcon({
  displayName: 'Moove It Logo',
  path: (
    <>
      <path
        d="M11.2347 5.15611C12.7067 6.6287 13.7772 7.69967 13.7772 8.5029C13.7772 9.26151 12.7067 10.3325 11.2347 11.8497C10.3872 12.6975 9.13828 13.947 8.02316 13.947C6.86343 13.947 6.23896 13.1884 6.23896 12.2067C6.23896 11.3142 7.08645 10.5556 7.4879 10.0647C8.24618 9.26151 8.69223 8.90452 8.69223 8.45828C8.69223 8.01204 8.24618 7.65505 7.4879 6.85182C7.04185 6.40558 6.23896 5.60235 6.23896 4.70987C6.23896 3.59428 7.08645 2.96954 7.93395 2.96954C8.73683 3.01417 9.49512 3.41578 11.2347 5.15611Z"
        fill="currentColor"
      />
      <path
        d="M3.78569 6.49488C2.53675 6.49488 0.707947 7.65511 0.707947 8.45833C0.707947 9.26156 2.53675 10.4218 3.78569 10.4218C4.90081 10.4218 6.06054 9.52931 6.06054 8.45833C6.06054 7.38736 4.90081 6.49488 3.78569 6.49488Z"
        fill="currentColor"
      />
      <path
        d="M21.8507 8.45843C21.8507 9.48478 20.2449 11.2697 18.7729 12.7423C17.0333 14.4827 15.7398 15.9999 14.3571 15.9999C13.6434 15.9999 12.5283 15.5982 12.5283 14.081C12.5283 13.1886 13.3757 12.2515 13.8218 11.8052C14.5801 11.002 16.4089 9.39553 16.4089 8.45843C16.4089 7.52133 14.5801 5.91487 13.8218 5.11164C13.3757 4.6654 12.5283 3.68367 12.5283 2.83582C12.5283 1.31861 13.6434 0.916992 14.3571 0.916992C15.7398 0.916992 17.0333 2.4342 18.7729 4.17454C20.2449 5.69175 21.8507 7.43208 21.8507 8.45843Z"
        fill="currentColor"
      />
    </>
  ),
});

export default React.memo(MooveitLogo);
