import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex,
  List,
  Stack,
  useColorModeValue,
  StackDivider,
  Box,
} from '@chakra-ui/react';
import {
  NAVIGATION_BACKGROUND_COLOR,
  NAVIGATION_DIVIDER_BACKGROUND_COLOR,
} from '@/utils/colorUtils';
import { useGetItemsFilteredByPermissions } from '@/utils/permissionsUtils';
import { useRouter } from 'next/router';
import UserProfile from '@/components/UserProfile';
import LogoutButton from '@/components/LogoutButton';
import FeedbackButton from '@/components/FeedbackButton';
import Tooltip from '@/components/Tooltip';
import { useUserPermissions } from '@/contexts/userPermissions';

import MenuItem from '../MenuItem';
import { menuItemsList } from '../menuItemsList';
import styles from '../NavigationMenu/NavigationMenu.module.css';

const NavigationBar = ({ isMenuOpen }) => {
  const router = useRouter();

  const navigationBackground = useColorModeValue(
    NAVIGATION_BACKGROUND_COLOR.light,
    NAVIGATION_BACKGROUND_COLOR.dark,
  );
  const navigationDividerBackground = useColorModeValue(
    NAVIGATION_DIVIDER_BACKGROUND_COLOR.light,
    NAVIGATION_DIVIDER_BACKGROUND_COLOR.dark,
  );

  const { permissionToLockEntries } = useUserPermissions();

  const menuItemsFiltered = useGetItemsFilteredByPermissions(
    menuItemsList({ permissionToLockEntries }),
  );

  const showMenuClass = isMenuOpen ? styles['is-open'] : '';

  const stackClassName = `${styles['navigation-menu']} ${showMenuClass}`;

  return (
    <Stack
      className={stackClassName}
      bg={navigationBackground}
      justifyContent="space-between"
      position="absolute"
      zIndex="docked"
      left={0}
      width={16}
    >
      <Stack>
        <UserProfile />
        <Stack
          px={2}
          spacing={3}
          divider={<StackDivider borderColor={navigationDividerBackground} />}
        >
          {menuItemsFiltered.map(({ options, title }) => (
            <List
              display="flex"
              flexDirection="column"
              gridRowGap="2"
              key={title}
              my={2}
            >
              {options.map(({ icon, label, url }) => {
                const basePath = router.pathname.split('/', 3).join('/');
                const isCurrentPage = url.includes(basePath);

                return (
                  <Box key={label} data-tip data-for={label}>
                    <MenuItem
                      icon={icon}
                      url={url}
                      isCurrentPage={isCurrentPage}
                    />
                    <Tooltip id={label} content={label} />
                  </Box>
                );
              })}
            </List>
          ))}
          <Box>
            <LogoutButton />
          </Box>
        </Stack>
      </Stack>
      <Flex px="2" pb="8">
        <FeedbackButton />
      </Flex>
    </Stack>
  );
};

NavigationBar.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

export default NavigationBar;
