import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const main = () => {
  const config = getConfig();
  if (config?.publicRuntimeConfig) {
    const { publicRuntimeConfig } = config;

    Sentry.init({
      dsn: publicRuntimeConfig.SENTRY_DSN,
      environment: publicRuntimeConfig.SENTRY_ENVIRONMENT,
      tracesSampleRate: 1.0,
    });
  }
};

main();
