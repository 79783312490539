import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Flex, useColorModeValue, Show, Hide, Link } from '@chakra-ui/react';
import {
  HEADER_BACKGROUND_COLOR,
  KUBRICK_LOGO_COLOR,
} from '@/utils/colorUtils';
import NavigationMenu from '@/components/Navigation/NavigationMenu/';
import NavigationBar from '@/components/Navigation/NavigationBar/';
import { APP_TIMER } from '@/constants/paths';
import {
  getSecondsUntilDayEnds,
  MILLISECONDS_IN_A_SECOND,
} from '@/utils/dateUtils';
import NextLink from 'next/link';
import SideMenuControl from '@/components/SideMenuControl';
import { KubrickLogoIcon } from '../icons';
import ThemeSwitch from '../ThemeSwitch';
import styles from './Header.module.css';

const Header = ({ isMenuOpen, showMenu, isInErrorPage }) => {
  const bg = useColorModeValue(
    HEADER_BACKGROUND_COLOR.light,
    HEADER_BACKGROUND_COLOR.dark,
  );
  const kubrickLogoColor = useColorModeValue(
    KUBRICK_LOGO_COLOR.light,
    KUBRICK_LOGO_COLOR.dark,
  );

  useEffect(() => {
    const timeoutTime = MILLISECONDS_IN_A_SECOND * getSecondsUntilDayEnds();

    const timeoutId = setTimeout(() => {
      window.location.reload();
    }, timeoutTime);

    return () => clearTimeout(timeoutId);
  }, []);

  const kubrickLogoIcon = (
    <NextLink href={APP_TIMER}>
      <Link href={APP_TIMER}>
        <KubrickLogoIcon color={kubrickLogoColor} w={120} h={30} />
      </Link>
    </NextLink>
  );

  if (!showMenu) {
    return (
      <Flex
        justify="space-between"
        align="center"
        padding={1}
        position="fixed"
        top={0}
        left={0}
        width="full"
        zIndex="dropdown"
        bg={bg}
        borderBottomWidth={1}
        className={styles.header}
      >
        <Flex justify="center" width="full">
          {kubrickLogoIcon}
        </Flex>
        {!isInErrorPage && <ThemeSwitch />}
      </Flex>
    );
  }

  return (
    <Flex
      justify="space-between"
      align="center"
      padding="1"
      position="fixed"
      top="0"
      left="0"
      width="full"
      zIndex="2"
      bg={bg}
      borderBottomWidth="1"
      className={styles.header}
    >
      <SideMenuControl />
      {kubrickLogoIcon}
      <ThemeSwitch />
      <Show above="lg">
        <NavigationMenu isMenuOpen={isMenuOpen} />
      </Show>
      <Hide above="lg">
        <NavigationBar isMenuOpen={isMenuOpen} />
      </Hide>
    </Flex>
  );
};

Header.propTypes = {
  isMenuOpen: PropTypes.bool,
  showMenu: PropTypes.bool,
  isInErrorPage: PropTypes.bool,
};

Header.defaultProps = {
  showMenu: true,
};

export default React.memo(Header);
