import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Application = createIcon({
  displayName: 'Application',
  viewBox: '0 0 12 13',
  path: (
    <>
      <path
        fill="currentColor"
        d="M1.333 5.778H4c.733 0 1.333-.6 1.333-1.333V1.778c0-.733-.6-1.333-1.333-1.333H1.333C.6.445 0 1.045 0 1.778v2.667c0 .733.6 1.333 1.333 1.333ZM1.333 12.445H4c.733 0 1.333-.6 1.333-1.334V8.445c0-.734-.6-1.334-1.333-1.334H1.333C.6 7.111 0 7.711 0 8.445v2.666c0 .734.6 1.334 1.333 1.334ZM6.667 1.778v2.667c0 .733.6 1.333 1.333 1.333h2.667c.733 0 1.333-.6 1.333-1.333V1.778c0-.733-.6-1.333-1.333-1.333H8c-.733 0-1.333.6-1.333 1.333ZM8 12.445h2.667c.733 0 1.333-.6 1.333-1.334V8.445c0-.734-.6-1.334-1.333-1.334H8c-.733 0-1.333.6-1.333 1.334v2.666c0 .734.6 1.334 1.333 1.334Z"
      />
    </>
  ),
});

export default React.memo(Application);
