import React from 'react';
import { createIcon } from '@chakra-ui/react';

const PersonSquare = createIcon({
  displayName: 'PersonSquare',
  viewBox: '0 0 18 18',
  path: (
    <path
      d="M0 2V16C0 17.1 0.89 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0H2C0.89 0 0 0.9 0 2ZM12 6C12 7.66 10.66 9 9 9C7.34 9 6 7.66 6 6C6 4.34 7.34 3 9 3C10.66 3 12 4.34 12 6ZM3 14C3 12 7 10.9 9 10.9C11 10.9 15 12 15 14V15H3V14Z"
      fill="currentColor"
    />
  ),
});

export default React.memo(PersonSquare);
