import React from 'react';
import PropTypes from 'prop-types';
import {
  MODAL_HEADER_COLOR,
  NAVIGATION_MENU_ITEM_TITLE_COLOR,
  SHORTCUTS_MODAL_BUTTON_BORDER_COLOR,
  SHORTCUTS_MODAL_BUTTON_TEXT_COLOR,
} from '@/utils/colorUtils';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Flex,
  ModalCloseButton,
  Stack,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  START_NEW_TIME_ENTRY,
  STOP_CURRENT_TIME_ENTRY,
  SWITCH_BETWEEN_TIMER_MODES,
  START_FAVORITE,
} from '@/constants/hotkeys';

import ShortcutItem from '../ShortcutItem';

const ShortcutsModal = ({ isOpen, onClose }) => {
  const modalHeaderColor = useColorModeValue(
    MODAL_HEADER_COLOR.light,
    MODAL_HEADER_COLOR.dark,
  );

  const shortcutItemColor = useColorModeValue(
    NAVIGATION_MENU_ITEM_TITLE_COLOR.light,
    NAVIGATION_MENU_ITEM_TITLE_COLOR.dark,
  );

  const borderButtonColor = useColorModeValue(
    SHORTCUTS_MODAL_BUTTON_BORDER_COLOR.light,
    SHORTCUTS_MODAL_BUTTON_BORDER_COLOR.dark,
  );

  const textButtonColor = useColorModeValue(
    SHORTCUTS_MODAL_BUTTON_TEXT_COLOR.light,
    SHORTCUTS_MODAL_BUTTON_TEXT_COLOR.dark,
  );

  const shortcutsItems = [
    {
      text: 'Start a new time entry',
      keyLegend: START_NEW_TIME_ENTRY,
    },
    {
      text: 'Stop current time entry',
      keyLegend: STOP_CURRENT_TIME_ENTRY,
    },
    {
      text: 'Switch between timer modes',
      keyLegend: SWITCH_BETWEEN_TIMER_MODES,
    },
    {
      text: 'Start favorite',
      keyLegend: START_FAVORITE,
    },
  ];

  return (
    <>
      <Modal onClose={onClose} size="xs" isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={modalHeaderColor}>Keyboard Shortcuts</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex>
              <Stack spacing={3} width="full" color={shortcutItemColor}>
                {shortcutsItems.map(shortcutItem => (
                  <ShortcutItem
                    key={shortcutItem.text}
                    text={shortcutItem.text}
                    keyLegend={shortcutItem.keyLegend}
                  />
                ))}
              </Stack>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button
              width="full"
              variant="outline"
              border="2px"
              onClick={onClose}
              color={textButtonColor}
              borderColor={borderButtonColor}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

ShortcutsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
};

export default ShortcutsModal;
