import {
  add,
  format,
  differenceInSeconds,
  parse,
  isToday,
  isYesterday,
  isValid,
  startOfWeek,
  endOfWeek,
  lastDayOfYear,
  getYear,
  isSameDay,
  sub,
  differenceInDays,
  startOfDay,
  endOfDay,
  isMatch,
  setSeconds,
  toDate,
  subSeconds,
} from 'date-fns';

import {
  WEEKLY_ID,
  DAILY_ID,
  MONTHLY_ID,
  INTEGER_RADIX,
} from '@/constants/global';

const MINIMUM_DATE = new Date(2020, 0, 1);
const MAXIMUM_DATE = lastDayOfYear(
  add(new Date(), {
    years: 2,
  }),
);

export const STANDARD_TIME_FORMAT = 'HH:mm:ss';
export const AM_PM_FORMAT_WITH_SIMPLE_HOUR = 'h:mm a';
const SHORT_TIME_FORMAT = 'hh:mm';

const AM_PM_FORMAT = 'hh:mm a';
const AM_PM_FORMAT_WITH_SINGLE_COLON = 'hh.mm a';
const AM_PM_FORMAT_WITH_SEMI_COLON = 'hh;mm a';
const AM_PM_FORMAT_WITH_COMMA = 'hh,mm a';
const AM_PM_FORMAT_WITHOUT_SPACE = 'hh:mma';
const AM_PM_FORMAT_WITH_SINGLE_COLON_AND_WITHOUT_SPACE = 'hh.mma';
const AM_PM_FORMAT_WITH_SEMI_COLON_AND_WITHOUT_SPACE = 'hh;mma';
const AM_PM_FORMAT_WITH_COMMA_AND_WITHOUT_SPACE = 'hh,mma';
const AM_PM_FORMAT_WITHOUT_COLON = 'hhmm a';
const AM_PM_FORMAT_WITHOUT_COLON_AND_WITHOUT_SPACE = 'hhmma';
const AM_PM_FORMAT_WITHOUT_COLON_AND_SIMPLE_HOUR = 'hmm a';
const AM_PM_FORMAT_WITHOUT_COLON_AND_SIMPLE_HOUR_AND_WITHOUT_SPACE = 'hmma';
const AM_PM_FORMAT_WITH_ONLY_SIMPLE_HOUR = 'h a';
const AM_PM_FORMAT_WITH_ONLY_SIMPLE_HOUR_AND_WITHOUT_SPACE = 'ha';
const AM_PM_FORMAT_WITH_ONLY_DOUBLE_HOUR = 'hh a';
const AM_PM_FORMAT_WITH_ONLY_DOUBLE_HOUR_AND_WITHOUT_SPACE = 'hha';
const TWENTY_FOUR_HOUR_FORMAT = 'HH:mm';
const TWENTY_FOUR_HOUR_FORMAT_WITH_SINGLE_COLON = 'HH.mm';
const TWENTY_FOUR_HOUR_FORMAT_WITH_SEMI_COLON = 'HH;mm';
const TWENTY_FOUR_HOUR_FORMAT_WITH_COMMA = 'HH,mm';
const TWENTY_FOUR_HOUR_FORMAT_WITHOUT_COLON = 'HHmm';
const TWENTY_FOUR_HOUR_FORMAT_WITH_ONLY_DOUBLE_HOUR = 'HH';

const timeFormatsList = [
  AM_PM_FORMAT,
  AM_PM_FORMAT_WITH_SINGLE_COLON,
  AM_PM_FORMAT_WITH_SEMI_COLON,
  AM_PM_FORMAT_WITH_COMMA,
  AM_PM_FORMAT_WITHOUT_SPACE,
  AM_PM_FORMAT_WITH_SINGLE_COLON_AND_WITHOUT_SPACE,
  AM_PM_FORMAT_WITH_SEMI_COLON_AND_WITHOUT_SPACE,
  AM_PM_FORMAT_WITH_COMMA_AND_WITHOUT_SPACE,
  AM_PM_FORMAT_WITHOUT_COLON,
  AM_PM_FORMAT_WITHOUT_COLON_AND_WITHOUT_SPACE,
  AM_PM_FORMAT_WITHOUT_COLON_AND_SIMPLE_HOUR,
  AM_PM_FORMAT_WITHOUT_COLON_AND_SIMPLE_HOUR_AND_WITHOUT_SPACE,
  AM_PM_FORMAT_WITH_ONLY_SIMPLE_HOUR,
  AM_PM_FORMAT_WITH_ONLY_SIMPLE_HOUR_AND_WITHOUT_SPACE,
  AM_PM_FORMAT_WITH_ONLY_DOUBLE_HOUR,
  AM_PM_FORMAT_WITH_ONLY_DOUBLE_HOUR_AND_WITHOUT_SPACE,
  TWENTY_FOUR_HOUR_FORMAT,
  TWENTY_FOUR_HOUR_FORMAT_WITH_SINGLE_COLON,
  TWENTY_FOUR_HOUR_FORMAT_WITH_SEMI_COLON,
  TWENTY_FOUR_HOUR_FORMAT_WITH_COMMA,
  TWENTY_FOUR_HOUR_FORMAT_WITHOUT_COLON,
  TWENTY_FOUR_HOUR_FORMAT_WITH_ONLY_DOUBLE_HOUR,
];

const TWENTY_FOUR_HOUR_FORMAT_WITHOUT_DIVIDER = time => time.length === 4;
const TWENTY_FOUR_HOUR_FORMAT_ONLY_DOUBLE_HOUR = time => time.length === 2;

const TIME_DIVIDERS = [':', '.', ';', ','];

const TIME_FORMAT_EXCEPTIONS = [
  {
    delimiter: new RegExp(String.raw`[${TIME_DIVIDERS.join()}]`, 'g'),
    parseWith: TWENTY_FOUR_HOUR_FORMAT,
  },
  {
    delimiter: TWENTY_FOUR_HOUR_FORMAT_WITHOUT_DIVIDER,
    parseWith: TWENTY_FOUR_HOUR_FORMAT_WITHOUT_COLON,
  },
  {
    delimiter: TWENTY_FOUR_HOUR_FORMAT_ONLY_DOUBLE_HOUR,
    parseWith: TWENTY_FOUR_HOUR_FORMAT_WITH_ONLY_DOUBLE_HOUR,
  },
];

// Difference here between using '01' (string) and 1 (number) is because octal literals are not allowed in strict mode
const PM_EQUIVALENT_HOURS = {
  '01': 13,
  '02': 14,
  '03': 15,
  '04': 16,
  '05': 17,
  '06': 18,
  1: 13,
  2: 14,
  3: 15,
  4: 16,
  5: 17,
  6: 18,
};

const STANDARD_DATE_FORMAT = 'yyyy/MM/dd';
const STANDARD_INPUT_DATE_FORMAT = 'MM/dd/yyyy';
const STANDARD_DATE_TIME_FORMAT = `${STANDARD_DATE_FORMAT} ${STANDARD_TIME_FORMAT}`;
const MANUAL_DATE_TIME_FORMAT = `${STANDARD_DATE_FORMAT} ${AM_PM_FORMAT}`;
const SHORT_DATE_FORMAT = 'MM/dd';
const DISPLAY_FORMAT = 'eee, d MMM';

const DAYS_IN_YEAR = 365;

const MONTHS_IN_A_YEAR = 12;
const TIME_CONVERSION_UNIT = 60;

const SECONDS_TO_HOURS = TIME_CONVERSION_UNIT * TIME_CONVERSION_UNIT;

const HOURS_IN_A_DAY = 24;

export const SECONDS_IN_A_DAY = 86400;

export const MILLISECONDS_IN_A_SECOND = 1000;

const DAYS_IN_A_MONTH = 31;
const SECONDS_IN_A_MONTH = SECONDS_IN_A_DAY * DAYS_IN_A_MONTH;

const LANGUAGE_DISPLAY = 'en-us';

const ONE_YEAR = 1;

export { isToday };

/**
 * Takes number of hours and returns the value as a number of seconds
 *
 * Examples:
 *
 *    convertHoursToSeconds(1) // => 3600
 *    convertHoursToSeconds(2) // => 7200
 *
 * @param {Number} hours a number representing hours
 *
 * @returns {Number}
 */
export const convertHoursToSeconds = hours => hours * SECONDS_TO_HOURS;

/**
 * Takes number of minutes and returns the value as a number of seconds
 *
 * Examples:
 *
 *    convertMinutesToSeconds(60) // => 3600
 *    convertMinutesToSeconds(120) // => 7200
 *
 * @param {Number} minutes a number representing minutes
 *
 * @returns {Number}
 */
export const convertMinutesToSeconds = minutes =>
  minutes * TIME_CONVERSION_UNIT;

/**
 * Returns the date formatted using `HH:mm:ss format
 *
 * Examples:
 *
 *    formatTime(new Date()) // => '21:00:00'
 *    formatTime(new Date()) // => '01:00:02'
 *
 * @param {Date} date a Date object representing the time to format
 *
 * @returns String
 */
export const formatTime = date => format(date, STANDARD_TIME_FORMAT);

/**
 * Returns the date formatted using `hh:mm` format
 *
 * Examples:
 *
 *    formatShortTime(new Date()) // => '02:05'
 *    formatShortTime(new Date()) // => '05:01'
 *
 * @param {Date} date a Date object representing the time to format
 *
 * @returns String
 */
export const formatShortTime = date => format(date, SHORT_TIME_FORMAT);

/**
 * Returns the date formatted using `hh:mm a` format
 *
 * Examples:
 *
 *    formatManualTime(new Date()) // => '02:05 PM'
 *    formatManualTime(new Date()) // => '05:01 AM'
 *
 * @param {Date} date a Date object representing the time to format
 *
 * @returns String
 */
export const formatManualTime = date =>
  format(date, AM_PM_FORMAT_WITH_SIMPLE_HOUR);

/**
 * Returns the date formatted using `yyyy/MM/dd` format
 *
 * Examples:
 *
 *    formatStandardDate(new Date()) // => '2021/01/01'
 *    formatStandardDate(new Date()) // => '2022/02/20'
 *
 * @param {Date} date a Date object representing the date to format
 *
 * @returns String
 */
export const formatStandardDate = date => format(date, STANDARD_DATE_FORMAT);

/**
 * Returns the date formatted using `MM/dd/yyyy` format
 *
 * Examples:
 *
 *    formatStandardInputDate(new Date()) // => '01/21/2021'
 *    formatStandardInputDate(new Date()) // => '02/20/2022'
 *
 * @param {Date} date a Date object representing the date to format
 *
 * @returns String
 */
export const formatStandardInputDate = date => {
  return format(date, STANDARD_INPUT_DATE_FORMAT);
};

/**
 * Returns the date formatted using `MM/dd` format
 *
 * Examples:
 *
 *    formatStandardInputDate(new Date()) // => '01/21'
 *    formatStandardInputDate(new Date()) // => '02/20'
 *
 * @param {Date} date a Date object representing the date to format
 *
 * @returns String
 */
export const formatShortDate = date => format(date, SHORT_DATE_FORMAT);

/**
 * Add the days given as parameter to the date given and return the new date
 *
 * Examples:
 *
 *    addDays(new Date(), 1) // => Wed Feb 23 2022 10:36:18 GMT-0300 (Uruguay Standard Time)
 *
 * @param {Date} date a Date object that is going to be used to add the days given
 * @param {Integer} days the number of days to add
 *
 * @returns Date
 */
export const addDays = (date, days) => add(date, { days });

/**
 * Subtract the days given as parameter to the date given and return the new date
 *
 * Examples:
 *
 *    subtractDays(new Date(), 1) // => Mon Feb 21 2022 10:36:18 GMT-0300 (Uruguay Standard Time)
 *
 * @param {Date} date a Date object that is going to be used to substract the days given
 * @param {Integer} days the number of days to substract
 *
 * @returns Date
 */
export const subtractDays = (date, days) => sub(date, { days });

/**
 * Add one year to the given date and return the new date
 *
 * Examples:
 *
 *    addAYear(new Date()) // => Mon Feb 21 2023 10:36:18 GMT-0300 (Uruguay Standard Time)
 *
 * @param {Date} date a Date object that is going to be used to add the year
 *
 * @returns Date
 */
export const addAYear = date => add(date, { years: ONE_YEAR });

/**
 * Remove one year to the given date and return the new date
 *
 * Examples:
 *
 *    subtractAYear(new Date()) // => Mon Feb 21 2021 10:36:18 GMT-0300 (Uruguay Standard Time)
 *
 * @param {Date} date a Date object that is going to be used to remove the year
 *
 * @returns Date
 */
export const subtractAYear = date => sub(date, { years: ONE_YEAR });

/**
 * @private
 */
const sumOverflowHoursToDate = (date, time) => {
  const splitTime = time?.split(':');
  const hours = splitTime && Number(splitTime[0]);

  if (hours >= HOURS_IN_A_DAY) {
    const daysToAdd = Math.floor(hours / HOURS_IN_A_DAY);

    const resultDate = addDays(new Date(date), daysToAdd);

    const newHours = hours - daysToAdd * HOURS_IN_A_DAY;

    const resultTime = `${newHours}:${splitTime[1]}:${splitTime[2]}`;

    return { resultDate: formatStandardDate(resultDate), resultTime };
  }

  return { resultDate: date, resultTime: time };
};

/**
 * Given a date and time join those together and return the date in ISO format
 *
 * @param {String} date the date to join in format 'yyyy/MM/dd'
 * @param {String} time the time to join in format 'hh:mm:ss'
 *
 * @returns String
 */
export const joinDateTimeToDate = (date, time) => {
  const { resultDate, resultTime } = sumOverflowHoursToDate(date, time);

  return parse(
    `${resultDate} ${resultTime}`,
    STANDARD_DATE_TIME_FORMAT,
    new Date(),
  );
};

/**
 * Given a date and time join those together and return the date in ISO format
 *
 * @param {String} date the date to join in format 'yyyy/MM/dd'
 * @param {String} time the time to join in format 'hh:mm:ss'
 *
 * @returns String
 */
export const joinManualDateTime = (date, time) => {
  return parse(
    `${date} ${time}`,
    MANUAL_DATE_TIME_FORMAT,
    new Date(),
  ).toISOString();
};

/**
 * Given a date and time join those together and return the date in ISO format,
 * if time is not given return a new Date
 *
 * @depracated use joinDateTimeToDate instead
 *
 * @param {String} date the date to join in format 'yyyy/MM/dd'
 * @param {String} time the time to join in format 'hh:mm:ss'
 *
 * @returns String
 */
export const joinDateTime = (date, time) => {
  if (time) {
    return joinDateTimeToDate(date, time).toISOString();
  }

  return new Date().toISOString();
};

/**
 * Given a date in string that has a format `yyyy/MM/dd` return a date object
 * representing that date
 *
 * @param {String} date the date to join in format 'yyyy/MM/dd'
 *
 * @returns Date
 */
export const parseStandardDate = dateString =>
  parse(dateString, STANDARD_DATE_FORMAT, new Date());

/**
 * Return Today or Yesterday or the date in the following format `eee, d MMM`
 *
 * @param {Date} date the date to format
 *
 * @returns String
 */
export const formatDisplayDate = date => {
  if (isToday(date)) {
    return 'Today';
  }
  if (isYesterday(date)) {
    return 'Yesterday';
  }

  return format(date, DISPLAY_FORMAT);
};

/**
 * Returns the time unit with a leading 0 if the time unit is less than 10.
 *
 * Examples:
 *
 *    padTimeUnit(1) # => '01'
 *    padTimeUnit(10) # => '10'
 *
 * @param {Number} timeUnit an number representing the time unit (seconds, minutes, or hours)
 *
 * @returns String
 */
export const padTimeUnit = timeUnit => {
  if (timeUnit < 10) {
    return `0${timeUnit}`;
  }

  return `${timeUnit}`;
};

/**
 * @private
 */
const displayTime = time => {
  const { hours, minutes, seconds } = time;

  return [hours, padTimeUnit(minutes), padTimeUnit(seconds)].join(':');
};

/**
 * Given the duration it transform that duration into their corresponding
 * time unit (time unit divided by hours, minutes, and seconds).
 *
 * Example:
 *
 *    secondsToTime(120) // => { hours: 0, minutes: 2, seconds: 0 }
 *    secondsToTime(3672) // => { hours: 1, minutes: 1, seconds: 12 }
 *
 * @param {Number} duration number of seconds to convert to hours, minutes, seconds
 *
 * @returns { hours: Number, minutes: Number, seconds: Number }
 */
export const secondsToTime = duration => {
  const hours = Math.floor(duration / SECONDS_TO_HOURS);
  const hoursInMinutes = hours * TIME_CONVERSION_UNIT;

  const minutes = Math.floor(duration / TIME_CONVERSION_UNIT - hoursInMinutes);
  const minutesInSeconds = minutes * TIME_CONVERSION_UNIT;

  const hoursInSeconds = hoursInMinutes * TIME_CONVERSION_UNIT;
  const seconds = Math.floor(duration - minutesInSeconds - hoursInSeconds);

  return { hours, minutes, seconds };
};

/**
 * Returns the amount of seconds in a H:mm:ss or HH:mm:ss format
 *
 * Example:
 *
 *  secondsToDisplayTime(4200) // => '1:10:00'
 *  secondsToDisplayTime(36000) // => '10:00:00'
 *
 * @param {number} seconds - Amount of seconds to be converted to display time
 *
 * @return {String}
 */
export const secondsToDisplayTime = seconds =>
  displayTime(secondsToTime(seconds || 0));

/**
 * Takes string in H:mm:ss or HH:mm:ss format and returns value as a number of seconds
 *
 * Example:
 *
 *  displayTimeToSeconds('1:10:00') // => 4200
 *  displayTimeToSeconds('10:00:00') // => 36000
 *
 * @param {String} displayTimeString - display time to be converted to number of seconds
 *
 * @return {Number}
 */
export const displayTimeToSeconds = displayTimeString => {
  const [hours, minutes, seconds] = displayTimeString
    .split(':')
    .map(timeString => Number(timeString));

  return (
    convertHoursToSeconds(hours) + convertMinutesToSeconds(minutes) + seconds
  );
};

/**
 * Returns the amount of days that the seconds represent
 *
 * Example:
 *
 *  secondsToDays(86400) // => 1
 *  secondsToDays(86399) // => 0
 *
 * @param {number} seconds - Amount of seconds to be transformed into days
 *
 * @return {number}
 */
export const secondsToDays = seconds => {
  const days = Math.floor(
    seconds / TIME_CONVERSION_UNIT / TIME_CONVERSION_UNIT / HOURS_IN_A_DAY,
  );

  return days;
};

/**
 * Return the difference between two dates expressed in the following format `H:mm:ss`
 *
 * @param {Date} startDate the date to start from
 * @param {Date} endDate the date to end at
 *
 * @returns String
 */
export const getDiff = (startDate, endDate) => {
  const secondsDiff = differenceInSeconds(endDate, startDate);
  const time = secondsDiff < 0 ? SECONDS_IN_A_DAY + secondsDiff : secondsDiff;

  return displayTime(secondsToTime(time));
};

// TODO: this function is too specific move to a better place
/**
 * Sum the duration of all time entries and return that value
 *
 * @param {Array<TimeEntry>} timeEntries an object containing durations
 *
 * @returns number
 */
export const getTotalTime = timeEntries => {
  const totalTime = timeEntries.reduce((totalTimeAccumulated, { duration }) => {
    return totalTimeAccumulated + duration;
  }, 0);

  return totalTime;
};

// TODO: this function is too specific move to a better place
/**
 * Add the difference between the start and end date in seconds to end date, so
 * if the end date is in a different day that difference is going to be handled
 * correctly
 *
 * @param {Date} startDate the date to start from
 * @param {Date} endDate the date to end at
 *
 * @returns Date
 */
export const generateFinishedAt = (startDate, endDate) => {
  const secondsDiff = differenceInSeconds(endDate, startDate);
  const finishedAt = add(endDate, { seconds: secondsDiff });

  return formatManualTime(finishedAt);
};

/**
 * Returns true if either date or date + time can be transformed into a valid date
 * false otherwise
 *
 * @param {String} date a string to be transformed into a date
 * @param {String} time a string to be transformed into a time
 *
 * @returns Boolean
 */
export const isDateValid = (date, time) => {
  if (time) {
    const parsedDate = parse(
      `${date} ${time}`,
      MANUAL_DATE_TIME_FORMAT,
      new Date(),
    );

    return isValid(parsedDate);
  }
  const parsedDate = parse(`${date}`, STANDARD_DATE_FORMAT, new Date());

  return isValid(parsedDate);
};

/**
 * Returns true if passed time is a valid time in standard format
 *
 * @param {String} time
 *
 * @returns Boolean
 */
export const isStandardTimeValid = time => {
  const parsedDate = parse(`${time}`, STANDARD_TIME_FORMAT, new Date());

  return isValid(parsedDate);
};

/**
 * Return the first date of the week corresponding to the day given
 *
 * @param {Date} day the date to be used to calculate the begging of the week
 *
 * @returns Date
 */
export const getStartOfWeek = day => {
  return startOfWeek(day, { weekStartsOn: 1 });
};

export const getEndOfWeek = day => {
  return endOfWeek(day, { weekStartsOn: 1 });
};

export const getEndOfWorkWeek = day => {
  return sub(endOfWeek(day, { weekStartsOn: 1 }), { days: 2 });
};

export const parseStringDate = stringDate => {
  return parse(stringDate, STANDARD_INPUT_DATE_FORMAT, new Date());
};

export const isDateInputFieldValid = inputDate => {
  const date = parseStringDate(inputDate);

  if (date < MINIMUM_DATE || date > MAXIMUM_DATE || !isValid(date)) {
    return false;
  }

  return true;
};

// TODO: this function is too specific move to a better place
// FIXME: use isTheSameDay instead of IsTheSameDay
export const IsTheSameDay = (firstDate, secondDate) => {
  return isSameDay(firstDate, secondDate);
};

// TODO: this function is too specific move to a better place
export const isRangeMoreThanAYear = (from, to) => {
  // TODO: we can use directly differenceInDays to avoid the two steps also change the order
  // of the params to avoid the negative sign
  const secondsDiff = -differenceInSeconds(from, to);
  const days = secondsToDays(secondsDiff);

  if (days > DAYS_IN_YEAR) {
    return true;
  }

  return false;
};

export const getDifferenceInDays = (from, to) => {
  return differenceInDays(to, from);
};

export const getStartOfDay = day => {
  return startOfDay(day);
};

export const getEndOfDay = day => {
  return endOfDay(day);
};

export const getDateRangeToGroupBy = rangeInSeconds => {
  if (rangeInSeconds <= SECONDS_IN_A_MONTH) {
    return DAILY_ID;
  }
  if (rangeInSeconds <= SECONDS_IN_A_MONTH * (MONTHS_IN_A_YEAR / 2)) {
    return WEEKLY_ID;
  }

  return MONTHLY_ID;
};

export const getShortWeekDisplay = date => {
  return `${formatShortDate(getStartOfWeek(date))} - ${formatShortDate(
    getEndOfWeek(date),
  )}`;
};

const getDaySuffix = day => {
  const st = [1, 21, 31];
  const nd = [2, 22];
  const rd = [3, 23];

  if (st.includes(day)) {
    return 'st';
  }
  if (nd.includes(day)) {
    return 'nd';
  }
  if (rd.includes(day)) {
    return 'rd';
  }

  return 'th';
};

export const getShortDayDisplay = date => {
  const dayName = date.toLocaleString(LANGUAGE_DISPLAY, { weekday: 'short' });

  return `${dayName} ${formatShortDate(date)}`;
};

export const getShortMonthDisplay = date => {
  const monthName = date.toLocaleString(LANGUAGE_DISPLAY, { month: 'short' });

  return `${monthName} ${getYear(date)}`;
};

export const getLongWeekDisplay = date => {
  const monthName = date.toLocaleString(LANGUAGE_DISPLAY, { month: 'long' });
  const from = `${monthName} ${getStartOfWeek(date).getDate()}${getDaySuffix(
    getStartOfWeek(date).getDate(),
  )}`;
  const to = `${monthName} ${getEndOfWeek(date).getDate()}${getDaySuffix(
    getEndOfWeek(date).getDate(),
  )}`;

  return `${from} - ${to} ${getYear(date)}`;
};

export const getLongDayDisplay = date => {
  const monthName = date.toLocaleString(LANGUAGE_DISPLAY, { month: 'long' });
  const dayName = date.toLocaleString(LANGUAGE_DISPLAY, { weekday: 'long' });

  return `${dayName}, ${monthName} ${date.getDate()}${getDaySuffix(
    date.getDate(),
  )}`;
};

export const getLongMonthDisplay = date => {
  const monthName = date.toLocaleString(LANGUAGE_DISPLAY, { month: 'long' });

  return `${monthName} ${getYear(date)}`;
};

export const getDaysInDateRange = (from, to) => {
  const daysInDateRange = [];

  for (
    let date = new Date(from);
    date <= to;
    date.setDate(date.getDate() + 1)
  ) {
    daysInDateRange.push(new Date(date));
  }

  return daysInDateRange;
};

// TODO: see if we can use secondsToTime instead
export const convertSecondsToHours = seconds =>
  seconds / TIME_CONVERSION_UNIT / TIME_CONVERSION_UNIT;

export const displayTimeFromHours = hours => {
  // TODO: see if we need to convert this to seconds or we can just to
  // `{ hours, minutes: 0, seconds: 0 }` and then call displayTime
  const seconds = convertHoursToSeconds(hours);

  return displayTime(secondsToTime(seconds));
};

export const handleManualFormatValue = value => {
  if (isMatch(value, STANDARD_TIME_FORMAT)) {
    return formatManualTime(parse(value, STANDARD_TIME_FORMAT, new Date()));
  }

  return value;
};
/*
 * getMinDateInRange takes an array of dates and returns the oldest (smallest)
 * if given an empty array it would return
 * Example:
 *   const dates = [d2, d1, d3, d4];
 *   getMinDateInRange(dates) // would return d1.  assuming is the smallest
 *   const emptyArray = [];
 *   getMinDateInRange(emptyArray) // would return null
 *
 *
 */
export const getMinDateInRange = dates => {
  if (dates.length === 0) {
    return Date(0);
  }

  return dates.reduce((a, b) => (new Date(a) < new Date(b) ? a : b));
};

export const handleStandardFormatValue = value => {
  if (isMatch(value, AM_PM_FORMAT)) {
    return formatTime(parse(value, AM_PM_FORMAT, new Date()));
  }

  return value;
};

export const setDateSeconds = (date, seconds) => {
  return setSeconds(date, seconds);
};

/**
 * This function takes "HH;mm", "HH;mm" ... (see TIME_FORMAT_EXCEPTIONS) and returns a Date
 *
 * @param {string} time
 *
 * @returns {Date}
 */
export const parseTimeExceptions = time => {
  let result = time;

  TIME_FORMAT_EXCEPTIONS.forEach(({ delimiter, parseWith }) => {
    if (typeof delimiter === 'function') {
      if (delimiter(time)) {
        result = parse(time, parseWith, new Date());
      }
    } else if (delimiter instanceof RegExp) {
      if (delimiter.test(time)) {
        const newValue = time.replace(delimiter, ':');

        result = parse(newValue, parseWith, new Date());
      }
    }
  });

  return result;
};

/**
 * This function takes a string as time param and
 * returns the time formatted using a hh:mm:ss format
 *
 * @param {string} time
 * @param {string} timeFormat defaults to standardFormat, the other option is manualTime
 *
 * @returns {string}
 */
export const parseTimeToFormat = (time, timeFormat = 'standardTime') => {
  let parsedTime;

  // Handle special cases not accepted by date-fns:

  // Three digit, without AM/PM (i.e. 111)
  if (time.length === 3) {
    // Prevent cases like 160, 199
    if (time.substring(1) >= 60) {
      return false;
    }

    const newValueWithoutAmPm = `0${time}`;

    if (isMatch(newValueWithoutAmPm, TWENTY_FOUR_HOUR_FORMAT_WITHOUT_COLON)) {
      parsedTime = parseTimeExceptions(newValueWithoutAmPm);
    }
  }

  // Special cases with AM/PM
  if (isMatch(time.substring(time.length - 2, time.length), 'a')) {
    const timeWithoutAmOrPm = time.substring(0, time.length - 2).trim();
    const amOrPm = time.substring(time.length - 2, time.length);

    // Cases like 0 PM, 00 AM, 0000PM
    if (
      timeWithoutAmOrPm === '0' ||
      timeWithoutAmOrPm === '00' ||
      timeWithoutAmOrPm === '0000'
    ) {
      const newValue = `12${amOrPm}`;

      parsedTime = parse(
        newValue,
        AM_PM_FORMAT_WITH_ONLY_DOUBLE_HOUR_AND_WITHOUT_SPACE,
        new Date(),
      );
    }

    if (timeWithoutAmOrPm.length === 2 && timeWithoutAmOrPm >= 24) {
      // Prevent cases like 24, 30, 99
      return false;
    }

    /* Three digit values, where first two digits are equal to or less than 12, with AM/PM (i.e. 111 AM).
     At this point, we only need to consider these kind of values since values where first two digits are greater than 12 are intercepted by the if that contains the for loop. */
    if (
      timeWithoutAmOrPm.length === 3 &&
      timeWithoutAmOrPm.substring(0, 2) <= 12
    ) {
      // Prevents cases starting with 0 like 000PM, 012 AM
      if (
        timeWithoutAmOrPm === '000' ||
        timeWithoutAmOrPm.substring(0, 1) === '0'
      ) {
        return false;
      }

      // Prevent cases like 160, 199
      if (time.substring(1) >= 60) {
        return false;
      }

      const newValueWithAmOrPm = `0${timeWithoutAmOrPm}${amOrPm}`;

      parsedTime = parse(
        newValueWithAmOrPm,
        AM_PM_FORMAT_WITHOUT_COLON_AND_SIMPLE_HOUR_AND_WITHOUT_SPACE,
        new Date(),
      );
    }

    // 24 hs format (i.e. 17.30 , 17;30 , 17,30 , 17;30 , 1730 or 17)
    // First validation prevents times like 154:45 from being accepted
    if (
      timeWithoutAmOrPm.length <= 5 &&
      timeWithoutAmOrPm.length !== 3 &&
      time.substring(0, 2) > 12
    ) {
      parsedTime = parseTimeExceptions(timeWithoutAmOrPm);
    }
  }

  if (!parsedTime) {
    for (let i = 0; i < timeFormatsList.length; i += 1) {
      // If we insert any of values 1, 2, 3, 4, 5 or 6 (without AM or PM) we will obtain the equivalent in PM format
      if (isMatch(time, timeFormatsList[i])) {
        const pmHour = PM_EQUIVALENT_HOURS[time] || time;

        parsedTime = parse(pmHour, timeFormatsList[i], new Date());
        break;
      }
    }
  }

  if (parsedTime) {
    if (timeFormat === 'standardTime') {
      return formatTime(parsedTime);
    }
    if (timeFormat === 'manualTime') {
      return formatManualTime(parsedTime);
    }
    throw Error('Please pass a valid time format!');
  }

  return time;
};

export const convertToDate = argument => {
  return toDate(argument);
};

export const checkIfDateRangeIsWeekly = (startDate, endDate) => {
  const weekStartDate = getStartOfWeek(startDate);
  const weekEndDate = getEndOfWeek(startDate);

  const dateRangeIsWeekly = !!(
    IsTheSameDay(startDate, weekStartDate) && IsTheSameDay(endDate, weekEndDate)
  );

  return dateRangeIsWeekly;
};

export const divideTimeInHalf = (startTime, endTime) => {
  const difference =
    differenceInSeconds(new Date(endTime), new Date(startTime)) / 2;

  return subSeconds(new Date(endTime), difference);
};

/**
 * Takes a duration string, parses it and returns the value as a number of seconds, if duration can't be parsed it returns null.
 *
 * Details:
 *
 *    1) If only numeric characters are passed, input will be treated as minutes and converted to seconds.
 *    2) If one or more numeric characters are passed followed by a single ':' character, input will be treated as hours and converted to seconds.
 *    3) If one or more numeric characters are passed followed by one or more non numeric characters, non numeric characters will be ignored,
 *    numeric characters will be treated a value of minutes and converted to seconds.
 *    4) If one or more numeric characters are passed followed by one or more non numeric character and this pattern repeats itself, the first three groups
 *    of numeric characters will be treated as hours, minutes and seconds respectively, they'll be aggregated and converted to seconds.
 *    5) If no numeric values are provided the function returns null.
 *
 * Examples:
 *
 *    1) parseDuration('15') // => 900
 *    2) parseDuration('15:') // => 54000
 *    3) parseDuration('15abc') // => 900
 *    4) parseDuration('1abc15:10') // => 4510
 *    5) parseDuration('some-text') // => null
 *
 * Other examples:
 *
 *    parseDuration('1:15') // => 4500
 *    parseDuration('01:15') // => 4500
 *    parseDuration('1:5') // => 3900
 *    parseDuration('1:5:5') // => 3905
 *    parseDuration('1:15:10') // => 4510
 *    parseDuration('01:15:10') // => 4510
 *
 *
 * @param {String} duration - A number as a string with no special characters is taken as minutes, a string in hh:mm:ss or hh:mm format can also be passed.
 *
 * @returns {Number|null}
 */
export const parseDuration = duration => {
  let durationInSeconds;

  const capturedData = duration?.match(/\d+|\D+/g) || [];

  const capturedNonNumericCharacters = capturedData.filter(item =>
    Number.isNaN(parseInt(item, INTEGER_RADIX)),
  );

  const capturedTime = capturedData
    .map(item => parseInt(item, INTEGER_RADIX))
    .filter(item => !Number.isNaN(item));

  if (capturedTime.length === 0) {
    durationInSeconds = null;
  } else if (capturedTime.length === 1) {
    if (
      capturedNonNumericCharacters.length &&
      capturedNonNumericCharacters[0] === ':'
    ) {
      const [hours] = capturedTime;

      durationInSeconds = convertHoursToSeconds(hours);
    } else {
      const [minutes] = capturedTime;

      durationInSeconds = convertMinutesToSeconds(minutes);
    }
  } else {
    const [hours = 0, minutes = 0, seconds = 0] = capturedTime;

    durationInSeconds =
      convertHoursToSeconds(hours) + convertMinutesToSeconds(minutes) + seconds;
  }

  return durationInSeconds;
};

/**
 * Generates a date object from a standardDate and a standardTime.
 *
 * @param {String} standardDate - A string in STANDARD_DATE_FORMAT ie:'yyyy/mm/dd'
 * @param {String} standardTime - A string a STANDARD_TIME_FORMAT ie: 'hh:mm:ss'
 *
 * @returns {Date}
 */
export const generateDate = (standardDate, standardTime) => {
  const date = new Date(standardDate);

  const hoursMinutesSeconds = standardTime
    .split(':')
    .map(timeValue => Number(timeValue));

  date.setHours(...hoursMinutesSeconds);

  return date;
};

/**
 * Returns true if timeB is less than timeA, if timeA or timeB can't be parsed as time returns false
 *
 * @param {Object} config
 * @param {String} config.timeA
 * @param {String} config.timeB
 * @param {Boolean} config.ignoreSeconds
 * @returns {Boolean}
 */
export const checkIsTimeBLessThanTimeA = ({
  timeA,
  timeB,
  ignoreSeconds = false,
}) => {
  const standardFormatTimeA = parseTimeToFormat(timeA);
  const standardFormatTimeB = parseTimeToFormat(timeB);

  if (
    isStandardTimeValid(standardFormatTimeA) &&
    isStandardTimeValid(standardFormatTimeB)
  ) {
    if (ignoreSeconds) {
      return (
        standardFormatTimeA.slice(0, -2) > standardFormatTimeB.slice(0, -2)
      );
    }

    return standardFormatTimeA > standardFormatTimeB;
  }

  return false;
};

/**
 * It returns the number of seconds up to now since the start of the current day
 * @returns {Number} The number of seconds up to now since the start of the current day.
 */
export const getSecondsUptoNow = () => {
  const timeNow = new Date();

  return (
    convertHoursToSeconds(timeNow.getHours()) +
    convertMinutesToSeconds(timeNow.getMinutes()) +
    timeNow.getSeconds()
  );
};

/**
 * It returns the number of seconds until the current day ends
 * @returns {Number} The number of seconds until the end of the current day.
 */
export const getSecondsUntilDayEnds = () => {
  const secondUpToNow = getSecondsUptoNow();

  return SECONDS_IN_A_DAY - secondUpToNow;
};
