import React from 'react';
import { createIcon } from '@chakra-ui/react';

const SelectUserOutline = createIcon({
  displayName: 'SelectUser',
  viewBox: '0 0 22 16',
  path: (
    <path
      d="M14.0482 11.2276L14.0493 11.2281C14.7898 11.6058 15.25 12.3725 15.25 13.22V15.25H0.75V13.22C0.75 12.3725 1.21017 11.6058 1.95074 11.2281L1.95175 11.2276C3.54631 10.4113 5.60976 9.75 8 9.75C10.3902 9.75 12.4537 10.4113 14.0482 11.2276ZM18.25 5V5.75H19H21C21.1358 5.75 21.25 5.86421 21.25 6C21.25 6.13579 21.1358 6.25 21 6.25H19H18.25V7V9C18.25 9.13579 18.1358 9.25 18 9.25C17.8642 9.25 17.75 9.13579 17.75 9V7V6.25H17H15C14.8642 6.25 14.75 6.13579 14.75 6C14.75 5.86421 14.8642 5.75 15 5.75H17H17.75V5V3C17.75 2.86421 17.8642 2.75 18 2.75C18.1358 2.75 18.25 2.86421 18.25 3V5ZM11.25 4C11.25 5.79579 9.79579 7.25 8 7.25C6.20421 7.25 4.75 5.79579 4.75 4C4.75 2.20421 6.20421 0.75 8 0.75C9.79579 0.75 11.25 2.20421 11.25 4Z"
      strokeWidth="1.6"
      fill="currentColor"
    />
  ),
});

export default SelectUserOutline;
