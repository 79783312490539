import React, { useEffect } from 'react';
import { useSubscription } from '@apollo/client';
import { useDisclosure } from '@chakra-ui/react';

import NewVersionModal from '@/components/NewVersionModal';
import { NEW_VERSION_SUBSCRIPTION } from '@/api/appSettings';
import { defaultUpdateMessage } from '@/components/GraphQLProvider/updateNotification';

const ChangeVersionModalContainer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: version } = useSubscription(NEW_VERSION_SUBSCRIPTION);

  useEffect(() => {
    if (version) {
      onOpen();
    }
  }, [version]);

  const notification = version?.versionUpdated?.notification
    ? JSON.parse(version?.versionUpdated?.notification)
    : defaultUpdateMessage;

  return (
    <>
      {version && (
        <NewVersionModal
          isOpen={isOpen}
          onClose={onClose}
          notification={notification}
        />
      )}
    </>
  );
};

export default ChangeVersionModalContainer;
