import {
  APP_TIMER,
  APP_REPORTS_SUMMARY,
  APP_MEMBERS,
  APP_SETTINGS_PROFILE,
  APP_STUDIOS,
  APP_SETTINGS_GENERAL,
  APP_CLIENTS,
} from '@/constants/paths';
import { STUDIOS_ID_FILTER, CLIENT_GROUP_ID_FILTER } from '@/constants/global';
import {
  ClockIcon,
  ReportIcon,
  PeopleIcon,
  CaseIcon,
  PersonSquareIcon,
} from '@/components/icons/index';
import { SettingsIcon } from '@chakra-ui/icons';

export const menuItemsList = ({ permissionToLockEntries }) => [
  {
    title: '',
    options: [
      {
        icon: ClockIcon,
        label: 'Timer',
        url: APP_TIMER,
        permissions: [],
      },
    ],
  },
  {
    title: 'Analyze',
    options: [
      {
        icon: ReportIcon,
        label: 'Reports',
        url: APP_REPORTS_SUMMARY,
        permissions: [],
      },
    ],
  },
  {
    title: 'Manage',
    options: [
      {
        icon: PeopleIcon,
        label: 'Members',
        url: APP_MEMBERS,
        permissions: [],
      },
      {
        icon: PersonSquareIcon,
        label: 'Clients',
        url: APP_CLIENTS,
        permissions: [{ read: true, entity: CLIENT_GROUP_ID_FILTER }],
      },
      {
        icon: CaseIcon,
        label: 'Studios',
        url: APP_STUDIOS,
        permissions: [{ read: true, entity: STUDIOS_ID_FILTER }],
      },
      {
        icon: SettingsIcon,
        label: 'Settings',
        url: permissionToLockEntries
          ? APP_SETTINGS_GENERAL
          : APP_SETTINGS_PROFILE,
        permissions: [],
      },
    ],
  },
];
