import React from 'react';
import PropTypes from 'prop-types';
import { Text, Kbd } from '@chakra-ui/react';

const ShortcutItem = ({ text, keyLegend }) => {
  return (
    <Text fontSize="md" display="flex" justifyContent="space-between">
      <span>{text}</span>
      <span>
        <Kbd fontSize="1em">{keyLegend}</Kbd>
      </span>
    </Text>
  );
};

ShortcutItem.propTypes = {
  text: PropTypes.string.isRequired,
  keyLegend: PropTypes.string.isRequired,
};

export default ShortcutItem;
