import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { useColorModeValue, useToken } from '@chakra-ui/react';
import {
  DEFAULT_TOOLTIP_OPEN_DELAY,
  DEFAULT_TOOLTIP_LOCATION,
} from '@/constants/global';

const Tooltip = ({ id, content, placement, delayShow, colorScheme }) => {
  const getContent = () => content;

  const [gray700, alpha600, gray500] = useToken('colors', [
    'gray.700',
    'alpha.600',
    'gray.500',
  ]);

  const darkColorScheme = colorScheme === 'primary' ? alpha600 : gray500;

  const tooltipColor = useColorModeValue(gray700, darkColorScheme);

  return (
    <ReactTooltip
      place={placement}
      delayShow={delayShow}
      id={id}
      getContent={getContent}
      backgroundColor={tooltipColor}
    />
  );
};

Tooltip.propTypes = {
  id: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
    PropTypes.node,
  ]),
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  delayShow: PropTypes.number,
  colorScheme: PropTypes.oneOf(['primary', 'secondary']),
};

Tooltip.defaultProps = {
  placement: DEFAULT_TOOLTIP_LOCATION,
  delayShow: DEFAULT_TOOLTIP_OPEN_DELAY,
  colorScheme: 'primary',
};

export default React.memo(Tooltip);
