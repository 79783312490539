const LOCK_ENTRIES = 'Lock entries';
const MANAGE_BILLABLE_ENTRIES = 'Manage billable entries';
const CHANGE_ROLES = 'Change roles';
const MANAGE_DISABLED_ENTRIES_PERMISSION = 'Manage disabled entries';
const SCOPE_GLOBAL = 'global';
const SCOPE_OWN = 'own';

const SUPER_ADMIN_HIERARCHY = 1;

export {
  LOCK_ENTRIES,
  MANAGE_BILLABLE_ENTRIES,
  CHANGE_ROLES,
  SCOPE_GLOBAL,
  SCOPE_OWN,
  SUPER_ADMIN_HIERARCHY,
  MANAGE_DISABLED_ENTRIES_PERMISSION,
};
