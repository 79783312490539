import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Clock = createIcon({
  displayName: 'Clock',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM14 14C13.61 14.39 12.98 14.39 12.59 14L9.3 10.71C9.11 10.52 9 10.27 9 10V6C9 5.45 9.45 5 10 5C10.55 5 11 5.45 11 6V9.59L14 12.59C14.39 12.98 14.39 13.61 14 14Z"
      fill="currentColor"
    />
  ),
});

export default Clock;
