import React from 'react';
import { createIcon } from '@chakra-ui/react';

const WarningTriangle = createIcon({
  displayName: 'Warning triangle',
  viewBox: '0 0 16 18',
  width: '17',
  height: '16',
  fill: 'none',
  path: (
    <path
      d="M15.9127 13.3333L9.68134 1.43332C9.56841 1.21782 9.39866 1.03732 9.19049 0.911382C8.98231 0.785446 8.74364 0.718872 8.50034 0.718872C8.25704 0.718872 8.01837 0.785446 7.81019 0.911382C7.60202 1.03732 7.43227 1.21782 7.31934 1.43332L1.08734 13.3333C0.980887 13.5366 0.928675 13.7638 0.935755 13.9931C0.942834 14.2224 1.00896 14.4461 1.12775 14.6423C1.24654 14.8386 1.41396 15.0009 1.61383 15.1136C1.8137 15.2262 2.03925 15.2854 2.26867 15.2853H14.7313C14.9608 15.2854 15.1863 15.2262 15.3862 15.1136C15.5861 15.0009 15.7535 14.8386 15.8723 14.6423C15.991 14.4461 16.0572 14.2224 16.0643 13.9931C16.0713 13.7638 16.0191 13.5366 15.9127 13.3333ZM7.83334 5.61532C7.83334 5.43851 7.90358 5.26894 8.0286 5.14392C8.15363 5.01889 8.3232 4.94865 8.50001 4.94865C8.67682 4.94865 8.84639 5.01889 8.97141 5.14392C9.09644 5.26894 9.16667 5.43851 9.16667 5.61532V9.61532C9.16667 9.79213 9.09644 9.9617 8.97141 10.0867C8.84639 10.2118 8.67682 10.282 8.50001 10.282C8.3232 10.282 8.15363 10.2118 8.0286 10.0867C7.90358 9.9617 7.83334 9.79213 7.83334 9.61532V5.61532ZM8.53334 13.2887H8.51467C8.25177 13.2878 7.99937 13.1854 7.81026 13.0027C7.62115 12.8201 7.50998 12.5714 7.50001 12.3087C7.4952 12.178 7.51648 12.0477 7.56261 11.9253C7.60874 11.803 7.67879 11.6911 7.76866 11.5961C7.85853 11.5011 7.96643 11.425 8.08605 11.3723C8.20567 11.3195 8.33461 11.291 8.46534 11.2887H8.48401C8.74686 11.289 8.99941 11.3909 9.18878 11.5732C9.37816 11.7555 9.48968 12.004 9.50001 12.2667C9.50518 12.3976 9.48412 12.5283 9.43806 12.651C9.39201 12.7737 9.32189 12.8859 9.23183 12.9812C9.14177 13.0764 9.03358 13.1526 8.91363 13.2054C8.79368 13.2582 8.66438 13.2865 8.53334 13.2887Z"
      fill="#F56565"
    />
  ),
});

export default React.memo(WarningTriangle);
