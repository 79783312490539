import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Play = createIcon({
  displayName: 'Play',
  viewBox: '0 0 15 20',
  path: (
    <path
      d="M0 1.62003V18.38C0 19.658 1.40745 20.4345 2.49134 19.7389L15.6599 11.3589C16.6629 10.728 16.6629 9.27201 15.6599 8.62491L2.49134 0.261113C1.40745 -0.434522 0 0.342001 0 1.62003Z"
      fill="currentColor"
    />
  ),
});

export default Play;
