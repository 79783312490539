import React from 'react';
import { createIcon } from '@chakra-ui/react';

const ChevronClose = createIcon({
  displayName: 'ChevronClose',
  viewBox: '-7 -7 36 36',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.99999 7C9.99999 6.44772 10.4477 6 11 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H11C10.4477 8 9.99999 7.55228 9.99999 7ZM7.70709 7.29289C8.09762 7.68342 8.09762 8.31658 7.70709 8.70711L4.4142 12L7.70709 15.2929C8.09762 15.6834 8.09762 16.3166 7.70709 16.7071C7.31657 17.0976 6.6834 17.0976 6.29288 16.7071L2.64643 13.0607C2.06065 12.4749 2.06065 11.5251 2.64643 10.9393L6.29288 7.29289C6.6834 6.90237 7.31657 6.90237 7.70709 7.29289ZM9.99999 12C9.99999 11.4477 10.4477 11 11 11H21C21.5523 11 22 11.4477 22 12C22 12.5523 21.5523 13 21 13H11C10.4477 13 9.99999 12.5523 9.99999 12ZM9.99999 17C9.99999 16.4477 10.4477 16 11 16H21C21.5523 16 22 16.4477 22 17C22 17.5523 21.5523 18 21 18H11C10.4477 18 9.99999 17.5523 9.99999 17Z"
      fill="currentColor"
    />
  ),
});

export default ChevronClose;
