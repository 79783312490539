import gql from 'graphql-tag';

const LIST_ROLES = gql`
  query {
    roles {
      id
      name
      hierarchy
    }
  }
`;

const SET_ROLE_PERMISSIONS_TO_USER = gql`
  mutation SetRolePermissionsToUser($userId: Float!, $roleId: Float!) {
    setUserPermissions(userId: $userId, roleId: $roleId) {
      userId
      permissionId
    }
  }
`;

const USER_ROLE = gql`
  query {
    userRole {
      id
      name
      hierarchy
    }
  }
`;

const UPDATE_ROLE_PERMISSIONS_TO_USER = gql`
  mutation UpdateRolePermissionsToUser(
    $userId: Float!
    $permissions: [PermissionFilterInput!]!
  ) {
    updateUserPermissions(userId: $userId, permissions: $permissions) {
      userId
    }
  }
`;

export {
  LIST_ROLES,
  SET_ROLE_PERMISSIONS_TO_USER,
  USER_ROLE,
  UPDATE_ROLE_PERMISSIONS_TO_USER,
};
