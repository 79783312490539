import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Text, useColorModeValue, Show, Hide } from '@chakra-ui/react';
import { CommandIcon } from '@/components/icons';
import {
  NAVIGATION_MENU_ITEM_COLOR,
  NAVIGATION_MENU_ITEM_BACKGROUND_HOVER_COLOR,
} from '@/utils/colorUtils';
import Tooltip from '@/components/Tooltip';

const ShortcutsButton = ({ onClick }) => {
  const navigationMenuItemBackgroundHoverColor = useColorModeValue(
    NAVIGATION_MENU_ITEM_BACKGROUND_HOVER_COLOR.light,
    NAVIGATION_MENU_ITEM_BACKGROUND_HOVER_COLOR.dark,
  );

  const navigationMenuItemColor = useColorModeValue(
    NAVIGATION_MENU_ITEM_COLOR.light,
    NAVIGATION_MENU_ITEM_COLOR.dark,
  );

  const buttonHoverStyles = {
    bg: navigationMenuItemBackgroundHoverColor,
  };

  const marginRight = { base: 0, lg: 3 };

  return (
    <Flex
      alignItems="center"
      bg="transparent"
      px="3"
      py="2"
      fontWeight="normal"
      rounded="lg"
      color={navigationMenuItemColor}
      _hover={buttonHoverStyles}
      data-tip
      data-for="shortcuts"
      width="full"
      onClick={onClick}
      cursor="pointer"
    >
      <CommandIcon w="5" h="5" mr={marginRight} />
      <Show above="lg">
        <Text>Shortcuts</Text>
      </Show>
      <Hide above="lg">
        <Tooltip id="shortcuts" content="Shortcuts" />
      </Hide>
    </Flex>
  );
};

ShortcutsButton.propTypes = {
  onClick: PropTypes.func,
};

export default ShortcutsButton;
