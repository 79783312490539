import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Folder = createIcon({
  displayName: 'Folder',
  viewBox: '0 0 20 16',
  path: (
    <path
      d="M8.59 0.59C8.21 0.21 7.7 0 7.17 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 14C0 15.1 0.9 16 2 16H18C19.1 16 20 15.1 20 14V4C20 2.9 19.1 2 18 2H10L8.59 0.59Z"
      fill="currentColor"
    />
  ),
});

export default React.memo(Folder);
