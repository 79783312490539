import { useUserPermissions } from '@/contexts/userPermissions';

export const hasPermission = permissions => {
  let result = true;

  permissions.forEach(permission => {
    const {
      userPermissions,
      additionalActionsPermissions,
    } = useUserPermissions();

    const userPermission = userPermissions[permission.entity] ?? {};

    const {
      create,
      read,
      update,
      delete: deletePermission,
      scope,
    } = userPermission;

    const matches =
      !!userPermission &&
      (!permission.create || permission.create === create) &&
      (!permission.read || permission.read === read) &&
      (!permission.update || permission.update === update) &&
      (!permission.delete || permission.delete === deletePermission) &&
      (!permission.scope || permission.scope === scope) &&
      (!permission.additionalActions ||
        permission.additionalActions?.every(additionalAction =>
          additionalActionsPermissions.includes(additionalAction),
        ));

    if (!matches) {
      result = false;
    }
  });

  return result;
};

export const useGetItemsFilteredByPermissions = itemList => {
  const filteredItems = itemList.map(({ title, options }) => {
    return {
      title,
      options: options.filter(option =>
        hasPermission(option.permissions || []),
      ),
    };
  });

  return filteredItems;
};
