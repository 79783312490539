import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Feedback = createIcon({
  displayName: 'Feedback',
  viewBox: '0 0 20 20',
  path: (
    <path
      d="M18 0H2C0.9 0 0 0.9 0 2V20L4 16H18C19.1 16 20 15.1 20 14V2C20 0.9 19.1 0 18 0Z"
      fill="currentColor"
    />
  ),
});

export default React.memo(Feedback);
