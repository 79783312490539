import React from 'react';
import {
  IconButton,
  useColorMode,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { MoonIcon, SunIcon } from '@chakra-ui/icons';
import { HEADER_ICONS_COLOR } from '@/utils/colorUtils';
import { useMutation } from '@apollo/client';
import { UPDATE_USER_PREFERENCES } from '@/api/users';
import { TOAST_DEFAULT_SUCCESS, TOAST_DEFAULT_ERROR } from '@/constants/global';
import { useSession } from 'next-auth/client';

const ThemeSwitch = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const toast = useToast();
  const [session] = useSession();
  const { email } = session?.user ?? {};

  const themeSwitchColor = useColorModeValue(
    HEADER_ICONS_COLOR.light,
    HEADER_ICONS_COLOR.dark,
  );

  const [update] = useMutation(UPDATE_USER_PREFERENCES, {
    onCompleted: () => {
      toast({
        title: 'User preferences updated successfully',
        description: 'Your preferences have been updated successfully',
        ...TOAST_DEFAULT_SUCCESS,
      });
    },
    onError: () => {
      toast(TOAST_DEFAULT_ERROR);
    },
  });

  const toggleColorTheme = () => {
    const preference = {
      input: {
        contactEmail: email,
        lightMode: colorMode === 'dark',
      },
    };

    toggleColorMode();
    update({ variables: preference });
  };

  const icon = colorMode === 'dark' ? <SunIcon /> : <MoonIcon />;
  const dataTestid = colorMode === 'dark' ? 'lightThemeBtn' : 'darkThemeBtn';

  return (
    <IconButton
      variant="ghost"
      colorScheme="delta"
      aria-label="Call Sage"
      fontSize="xl"
      icon={icon}
      onClick={toggleColorTheme}
      color={themeSwitchColor}
      data-testid={dataTestid}
    />
  );
};

export default React.memo(ThemeSwitch);
