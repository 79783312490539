import gql from 'graphql-tag';

const TIME_ENTRY_BLOCK_DATE = gql`
  query {
    timeEntriesBlockDate {
      blockDate
    }
  }
`;

const UPDATE_TIME_ENTRY_BLOCK_DATE = gql`
  mutation UpdateBlockDate($input: UpdateBlockDateInput!) {
    updateBlockDate(input: $input) {
      id
      blockDate
    }
  }
`;

const BLOCK_DATE_UPDATE_SUBSCRIPTION = gql`
  subscription BlockDateUpdated {
    blockDateUpdated {
      blockDate
    }
  }
`;

const UPDATE_NEW_VERSION = gql`
  mutation UpdateAppVersion($newVersion: String!, $notification: String!) {
    updateAppVersion(newVersion: $newVersion, notification: $notification) {
      value
      notification
    }
  }
`;

const NEW_VERSION_SUBSCRIPTION = gql`
  subscription VersionUpdated {
    versionUpdated {
      value
      notification
    }
  }
`;

const FORCE_SYNC = gql`
  mutation ClearApiCache {
    clearApiCache
  }
`;

const APP_SETTINGS = gql`
  query {
    appSettings {
      name
      value
    }
  }
`;

const UPDATE_PROJECT_REQUIRED = gql`
  mutation UpdateProjectRequired($isProjectRequired: String!) {
    updateProjectRequired(isProjectRequired: $isProjectRequired) {
      value
    }
  }
`;

const UPDATE_TAG_REQUIRED = gql`
  mutation UpdateTagRequired($isTagRequired: String!) {
    updateTagRequired(isTagRequired: $isTagRequired) {
      value
    }
  }
`;

const PROJECT_REQUIRED_SUBSCRIPTION = gql`
  subscription ProjectRequiredUpdated {
    projectRequiredUpdated {
      value
    }
  }
`;

const TAG_REQUIRED_SUBSCRIPTION = gql`
  subscription TagRequiredUpdated {
    tagRequiredUpdated {
      value
    }
  }
`;

const CACHE_VERSION = gql`
  query {
    cacheVersion {
      id
      cacheVersion
      notification
    }
  }
`;

const CACHE_VERSION_SUBSCRIPTION = gql`
  subscription CacheVersionUpdated {
    cacheVersionUpdated {
      id
      cacheVersion
      notification
    }
  }
`;

const SYNC_STUDIOS_AND_USERS = gql`
  mutation {
    syncStudiosAndUsers {
      name
    }
  }
`;

const UPLOAD_HOLIDAYS = gql`
  mutation($holidaysAsCSV: String!) {
    addHolidays(holidaysAsCSV: $holidaysAsCSV) {
      message
      holiday {
        id
        date
      }
    }
  }
`;

const GET_HOLIDAYS = gql`
  query($from: String!, $to: String) {
    countriesOnHoliday(from: $from, to: $to) {
      id
      date
      country {
        name
        id
      }
    }
  }
`;

const GET_HOLIDAY_CSV = gql`
  query GetHolidaysCSV {
    getHolidaysCSV
  }
`;

const DELETE_HOLIDAY = gql`
  mutation($countryId: Float!, $date: String!) {
    removeHoliday(countryId: $countryId, date: $date)
  }
`;

const GET_CSV_VERSION = gql`
  query GetCSVVersion {
    getCSVVersion {
      value
    }
  }
`;

export {
  TIME_ENTRY_BLOCK_DATE,
  UPDATE_TIME_ENTRY_BLOCK_DATE,
  BLOCK_DATE_UPDATE_SUBSCRIPTION,
  NEW_VERSION_SUBSCRIPTION,
  UPDATE_NEW_VERSION,
  FORCE_SYNC,
  APP_SETTINGS,
  UPDATE_PROJECT_REQUIRED,
  UPDATE_TAG_REQUIRED,
  PROJECT_REQUIRED_SUBSCRIPTION,
  TAG_REQUIRED_SUBSCRIPTION,
  CACHE_VERSION,
  CACHE_VERSION_SUBSCRIPTION,
  SYNC_STUDIOS_AND_USERS,
  UPLOAD_HOLIDAYS,
  GET_HOLIDAYS,
  GET_HOLIDAY_CSV,
  GET_CSV_VERSION,
  DELETE_HOLIDAY,
};
