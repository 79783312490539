import React from 'react';
import { createIcon } from '@chakra-ui/react';

const Case = createIcon({
  displayName: 'Moove It Brand',
  path: (
    <path
      d="M7 5V2C7 1.44772 7.44772 1 8 1H16C16.5523 1 17 1.44772 17 2V5H21C21.5523 5 22 5.44772 22 6V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V6C2 5.44772 2.44772 5 3 5H7ZM4 15V19H20V15H4ZM11 11V13H13V11H11ZM9 3V5H15V3H9Z"
      fill="currentColor"
    />
  ),
  viewBox: '0 0 24 24',
});

export default React.memo(Case);
