import React, { useEffect } from 'react';
import App from 'next/app';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { Provider as NextAuthProvider } from 'next-auth/client';
import * as Sentry from '@sentry/nextjs';

import { sessionType } from '@/utils/types';
import Layout from '@/layouts/Main';
import { ROOT } from '@/constants/paths';
import { isErrorPage } from '@/utils/urlUtils';
import { pageview, GaInitializer } from '@/lib/ga';
import GraphQLProvider from '@/components/GraphQLProvider';
import ChakraThemeProvider from '../providers';

// Import styles from react-day-picker
import 'react-day-picker/dist/style.css';

const KubrickWeb = ({ Component, pageProps }) => {
  const router = useRouter();
  const { session } = pageProps;

  useEffect(() => {
    const { pathname } = router;

    if (!session && !isErrorPage(pathname)) {
      router.push(ROOT);
    }
  }, [session]);

  useEffect(() => {
    if (session) {
      Sentry.setUser({
        email: session.user.email,
        name: session.user.name,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [session]);

  useEffect(() => {
    const handleRouteChange = url => {
      pageview(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const checkRenderWithLayout = () => {
    if (session) {
      return (
        <GraphQLProvider authToken={session?.user?.authToken}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </GraphQLProvider>
      );
    }

    return <Component {...pageProps} />;
  };

  return (
    <NextAuthProvider
      options={{
        clientMaxAge: 0,
        keepAlive: 0,
      }}
      session={session}
    >
      {GaInitializer()}
      <ChakraThemeProvider>{checkRenderWithLayout()}</ChakraThemeProvider>
    </NextAuthProvider>
  );
};

// Sentry is trying to get this function in order to fix a bug in the nextjs error page,
// if is undefined the sentry error will not work properly
KubrickWeb.getInitialProps = async appContext => {
  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  return { ...appProps };
};

KubrickWeb.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  pageProps: PropTypes.shape({
    session: sessionType,
  }).isRequired,
};

export default KubrickWeb;
