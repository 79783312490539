import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ApolloProvider } from '@apollo/client';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';

import { cache, getApolloClient } from './apolloClient';

const GraphQLProvider = ({ authToken, children }) => {
  const [loading, setLoading] = useState(true);
  const [apolloClient, setApolloClient] = useState(null);

  useEffect(() => {
    const loadStorage = async () => {
      const storage = new LocalStorageWrapper(window.sessionStorage);

      await persistCache({ cache, storage });

      setLoading(false);
    };

    loadStorage();

    const apollo = getApolloClient(authToken);

    setApolloClient(apollo);
  }, []);

  return (
    !loading && (
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    )
  );
};

GraphQLProvider.propTypes = {
  authToken: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default GraphQLProvider;
