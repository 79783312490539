const PROJECTS = 'projects';
const TAGS = 'tags';
const BILLABLE = 'billable';
const TO_BILL = 'toBill';
const CLIENTS = 'clients';
const DESCRIPTION = 'description';
const EXACT_DESCRIPTION = 'exact';
const USERS = 'users';
const STUDIOS = 'studios';
const APPLICATIONS = 'applications';
const CLIENT_GROUP = 'businessUnit';
const ROLE = 'role';
const SYNCED = 'synced';

const YES_PARAM = 'yes';
const NO_PARAM = 'no';

export {
  PROJECTS,
  TAGS,
  BILLABLE,
  CLIENTS,
  DESCRIPTION,
  EXACT_DESCRIPTION,
  USERS,
  STUDIOS,
  APPLICATIONS,
  YES_PARAM,
  NO_PARAM,
  ROLE,
  SYNCED,
  CLIENT_GROUP,
  TO_BILL,
};
