import { makeVar } from '@apollo/client';
import { DEFAULT_UPDATE_NOTIFICATION } from '@/constants/global';

export const defaultUpdateMessage = [
  {
    type: 'paragraph',
    children: [
      {
        text: DEFAULT_UPDATE_NOTIFICATION,
      },
    ],
  },
];

const updateNotification = makeVar(defaultUpdateMessage);

export default updateNotification;
