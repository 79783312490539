import React, { useContext, useCallback, useMemo } from 'react';
import getConfig from 'next/config';
import { signOut } from 'next-auth/client';
import {
  Button,
  useColorModeValue,
  Show,
  Hide,
  useConst,
} from '@chakra-ui/react';
import { OnlineStatusContext } from '@/components/OnlineStatusContext';
import { LogoutIcon } from '@/components/icons';
import {
  NAVIGATION_MENU_ITEM_COLOR,
  NAVIGATION_MENU_ITEM_BACKGROUND_HOVER_COLOR,
} from '@/utils/colorUtils';
import Tooltip from '@/components/Tooltip';
import { APP_LOGOUT } from '@/constants/paths';
import { clearStorageCustom } from '@/utils/clearStorageUtils';

const { publicRuntimeConfig } = getConfig();

const LogoutButton = () => {
  const isOnline = useContext(OnlineStatusContext);

  const navigationMenuItemBackgroundHoverColor = useColorModeValue(
    NAVIGATION_MENU_ITEM_BACKGROUND_HOVER_COLOR.light,
    NAVIGATION_MENU_ITEM_BACKGROUND_HOVER_COLOR.dark,
  );

  const navigationMenuItemColor = useColorModeValue(
    NAVIGATION_MENU_ITEM_COLOR.light,
    NAVIGATION_MENU_ITEM_COLOR.dark,
  );

  const handleLogout = useCallback(event => {
    event.preventDefault();
    clearStorageCustom();

    const logoutUrl = `https://${publicRuntimeConfig.AUTH0_DOMAIN}/v2/logout?client_id=${publicRuntimeConfig.AUTH0_ID}&returnTo=${publicRuntimeConfig.BASE_URL}${APP_LOGOUT}`;

    signOut({ callbackUrl: logoutUrl });
  }, []);

  const buttonHover = useMemo(
    () => ({
      bg: navigationMenuItemBackgroundHoverColor,
    }),
    [navigationMenuItemBackgroundHoverColor],
  );

  const marginTop = useConst({ base: 2, lg: 0 });
  const marginRight = useConst({ base: 0, lg: 3 });

  return (
    <Button
      onClick={handleLogout}
      justifyContent="start"
      alignItems="center"
      bg="transparent"
      height="auto"
      mt={marginTop}
      ml="px"
      px={3}
      py={2}
      fontWeight="normal"
      rounded="lg"
      color={navigationMenuItemColor}
      _hover={buttonHover}
      isDisabled={!isOnline}
      data-tip
      data-for="log-out"
    >
      <LogoutIcon w={5} h={5} mr={marginRight} />
      <Show above="lg">Log out</Show>
      <Hide above="lg">
        <Tooltip id="log-out" content="Logout" />
      </Hide>
    </Button>
  );
};

export default LogoutButton;
